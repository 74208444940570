import { Topic } from "../content";
import { Culture } from "../cultures";
import { Insight } from "./insight.model";
import { Subject } from "./subject.model";
import { Task } from "./task.model";

export class Delivery {
    id: number;
    task: Task;
    subject: Subject;
    insights: Insight[];
    source_lang: string;
    target_lang: string;
    user_id: number;
    handler_id: number;
    started_at: Date | null;
    submitted_at: Date | null;
    reopen_count: number;
    abandoned_at: Date | null;
    completed_at: Date | null;
    credits: number;
    cultures: Culture[];
    topics: Topic[];

    constructor(
        id: number,
        task: Task,
        subject: Subject,
        insights: Insight[],
        source_lang: string,
        target_lang: string,
        userId: number,
        handler_id: number,
        started_at: Date | null,
        submitted_at: Date | null,
        reopen_count: number,
        abandoned_at: Date | null,
        completed_at: Date | null,
        credits: number
    ) {
        this.id = id;
        this.task = task;
        this.subject = subject;
        this.insights = insights;
        this.source_lang = source_lang;
        this.target_lang = target_lang;
        this.user_id = userId;
        this.handler_id = handler_id;
        this.started_at = started_at;
        this.submitted_at = submitted_at;
        this.reopen_count = reopen_count;
        this.abandoned_at = abandoned_at;
        this.completed_at = completed_at;
        this.credits = credits;
    }
}