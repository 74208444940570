<div class="national-culture-labels" *ngIf="culturesWithVariants()">
  <div [class]="'national-culture-visualisation' + (culturesWithVariants().length > 1 ? '-group' : '-single')">
    <multisite-national-culture-visualisation
    *ngIf="culturesWithVariantsSubGroup()[0]"
    [cultureWithVariants]="culturesWithVariantsSubGroup()[0]"
    [selectedVariantFiltersDynamic]="selectedVariantFilters()"
    [showIcons]="showIcons()"
    [hideNumberLabels]="true"
    [hideTextLabels]="true"
    [showControls]="false"
    [cardHeight]="cardHeight()"
    [cardWidth]="cardWidth()"
    (click)="doSelectCulture(culturesWithVariantsSubGroup()[0])"
    ngClass="left"
    >
  </multisite-national-culture-visualisation>
  
  <div [class]="culturesWithVariants().length > 1 ? 'mid' : ''">
      <multisite-national-culture-visualisation
        [cultureWithVariants]="selectedCulture()"
        [selectedVariantFiltersDefault]="selectedVariantFiltersDefault()"
        (updateSelectedVariantFilters)="doUpdateSelectedVariantFilters($event)"
        [showIcons]="showIcons()"
        [cardHeight]="cardHeight()"
        [cardWidth]="cardWidth()"    
        [hideNumberLabels]="hideNumberLabels()"
        [hideTextLabels]="hideTextLabels()"
        [labelType]="labelType()"
        [highlightMass]="highlightMass()"
        (toggleShowFilters)="doToggleShowFilters()"
        [showFilters]="showFilters()"
        [showControls]="showControls()"
        [autoPlayFilterLoop]="autoPlayFilterLoop()"
      >
      </multisite-national-culture-visualisation>
    </div>

       <multisite-national-culture-visualisation
          *ngIf="culturesWithVariantsSubGroup()[0]"
          [cultureWithVariants]="culturesWithVariantsSubGroup()[1]"
          [selectedVariantFiltersDynamic]="selectedVariantFilters()"
          [showIcons]="showIcons()"
          [cardHeight]="cardHeight()"
          [cardWidth]="cardWidth()"      
          [hideNumberLabels]="true"
          [hideTextLabels]="true"
          [showControls]="false"
          (click)="doSelectCulture(culturesWithVariantsSubGroup()[1])"
          ngClass="right"
        >
        </multisite-national-culture-visualisation>

    <!-- @if(culturesWithVariantsSubGroup().length){
      <div class="sub-group">
        @for(cultureWithVariants of culturesWithVariantsSubGroup(); track cultureWithVariants.id){
            <multisite-national-culture-visualisation
              [cultureWithVariants]="cultureWithVariants"
              [selectedVariantFiltersDynamic]="selectedVariantFilters()"
              [showIcons]="showIcons()"
              [hideNumberLabels]="true"
              [hideTextLabels]="true"
              [showControls]="false"
              (click)="doSelectCulture(cultureWithVariants)"
            >
            </multisite-national-culture-visualisation>
        }
      </div>

    } -->

    
        
       


  </div>
  @if(show_legend){
    
    <div class="legend">

      <button class="btn btn-link" (click)="doToggleLabelType()"><i class="fa fa-info mr-2"></i><span *transloco="let t">{{t('common.'+labelType())}}</span></button>
      <a (click)="doToggleShowLegendDetails()" *transloco="let t"
        class="text-nowrap link-primary-dark fw-bold d-flex align-items-center gap-2">{{
        show_legend_details()
        ? t('common.show_less')
        : t('common.show_more')
        }}
        <span *ngIf="!show_legend_details()"><i class="fa-solid fa-plus"></i></span>
        <span *ngIf="show_legend_details()"><i class="fa-solid fa-minus"></i></span>
      </a>

      @for(massVariantCollection of selectedMassVariantCollectionsOfCollections(); track massVariantCollection){
        @if(massVariantCollection.variants?.length){
          <h5 *transloco="let t; scope : 'diversity'">{{t(massVariantCollection.titleTranslationKey)}}</h5>
          @if(massVariantCollection.helptextTranslationKey1 || massVariantCollection.helptextTranslationKey2){
            <p *transloco="let t; scope : 'diversity'">
              @if(massVariantCollection.helptextTranslationKey1){
                {{t(massVariantCollection.helptextTranslationKey1)}}
              }
              @if(massVariantCollection.helptextTranslationKey2){
                {{t(massVariantCollection.helptextTranslationKey2)}}
              }
            </p>
          }
          @for(variant of massVariantCollection.variants; track variant.id){
        
            <div class="label">
              <span [ngClass]="{'segment-pointer': massVariantCollection.titleTranslationKey == 'diversity.in_focus'}"></span>
              <div class="title">
                @if(!hideNumberLabels() && labelType() === 'number'){
                  <div class="label-round">
                    {{variant.number}}
                  </div>
                }
                @if(massVariantCollection.titleTranslationKey == 'diversity.in_focus'){
                  <!-- <div class="segment-pointer text-danger">
                    <i class="fa-duotone fa-solid fa-circle-dot"></i>
                  </div> -->
                }
                <div class="h6" *transloco="let t; scope:'diversity'">
                  @if(variant.name){
                    {{variant.name}}
                  } @else {
                    {{t(variant.name_short_translation_key)}}
                  }
                </div>
              </div>
              @if(show_legend_details()){
                <p *transloco="let t; scope:'diversity'">
                  @if(variant.description){
                    {{variant.description}}
                  } @else {
                    {{t(variant.description_translation_key)}}
                  }
                </p>
              }
            </div>
          }
        }
      }
    </div>
  }
</div>
