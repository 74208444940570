import { Component, OnDestroy, OnInit, WritableSignal, computed, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, debounceTime } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EditorConfig } from '@ckeditor/ckeditor5-core';


import { Delivery, ContributionsService, Task} from '..';
import { AuthService, User } from '../../auth';
import { BreadcrumbService } from '../../navigation/breadcrumb/breadcrumb.service';
import { Gallery, MediaService } from '../../content/media';
import { Language, LanguageService } from '../../language';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { ProfileUserService } from '../../profile-user/profile-user.service';
import { MetaText } from '../../content';
import { Culture, CultureService, CultureVariantFilter } from '../../cultures';
import { TranslationService } from '../../translation/translation.service';
import { FormCachingService } from '../../utilities';
import { PageTitleService } from '../../navigation';
import { TranslocoService } from '@jsverse/transloco';
import { MetaTextService } from '../../content/metatext.service';

@Component({
  selector: 'multisite-insight-edit',
  templateUrl: './insight-edit.component.html',
  styleUrls: ['./insight-edit.component.scss']
})
export class InsightEditComponent implements OnInit, OnDestroy {
  
  insightForm : FormGroup;
  loadingObject = signal <{[key:string]:boolean}>({});
  allLanguages = signal<Language[]|undefined>(undefined);
  myLanguagesApprovedForEditing = signal<Language[]|undefined>(undefined);
  englishLanguage = computed(() => this.allLanguages()?.find(l=>l.iso === 'en'));
  cloudinary_base_url : WritableSignal<string> = signal<string>(null);
  instructions = signal<MetaText[]>([]);
  instructionsPreferredOrder = ['rules','generalising','judging','general','style','writing','targeting','sources'];
  instructionTypes = computed(() => {
    const availableTypes = this.instructions().map(instruction => instruction.type).filter((value, index, self) => self.indexOf(value) === index);
    return this.instructionsPreferredOrder.filter(type => availableTypes.includes(type)).concat(availableTypes.filter(type => !this.instructionsPreferredOrder.includes(type)));
  });;
  show_instruction_details = signal<boolean>(false);
  subscriptions : Subscription[] = [];
  cultures : Culture[];
  culturesWithVariants = signal<Culture[] | undefined>(undefined);
  cultureGroupSlugs : string[] = ['france'];
  delivery: Delivery;
  taskType: 'create' | 'review' | 'translate'; // for example, 'create' or 'review
  selectedLanguage : Language;
  visualisationLabelType = signal<string>('number');
  show_visualisation_legend = signal<boolean>(true);
  show_visualisation_legend_details = signal<boolean>(false);
  show_visualisation_filters = signal<boolean>(false);
  show_visualisation_controls = signal<boolean>(true);
  visualisationCardHeight = signal<number>(400);
  visualisationCardWidth = signal<number>(240);
  active_visualisation_filters = signal<{[key:string]:boolean}>({});
  autoPlayFilterLoop = signal<boolean>(false);
  visualisationFilters = signal<CultureVariantFilter[]>([]);
  selectedVisualisationFilters = signal<CultureVariantFilter[]>([]); // only for the initial state of the visualisation
  highlightMassCultureItemsInVisualisation = signal<boolean>(true);


  configSimple : EditorConfig;
  configReactiveForm : EditorConfig;
  editorDataSimple : string;
  editorDataReactiveForm : string;
  toolbarSimple: {items:string[]};
  toolbarReactiveForm: {items:string[]};
  editorCharacters : number;
  editorContent : string;
  languageChooserForm : FormGroup;

  
  slug: string; // insight slug
  error = null;
  user : User = null;
  userSubscription: Subscription;
  isAuthenticated : boolean;

  languagesSubscription: Subscription;
  languages : Language[];
  gallerySubscription: Subscription;
  galleryItemSelectSubscription: Subscription;
  fileUploadSubscription: Subscription;
  fileUploadError: string;
  fileUploadSuccess: string;
  uploadProgress:{[key:string]:{[key:string]:number}} = {}; // {'profile':{'feature':77}}
  gallery : Gallery;




  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private contributionsService: ContributionsService,
    private breadcrumbService : BreadcrumbService,
    private authService : AuthService,
    private languageService : LanguageService,
    private mediaService : MediaService,
    private profileUserService : ProfileUserService,
    private sanitizer: DomSanitizer,
    private cultureService : CultureService,
    private translationService : TranslationService,
    private formCachingService: FormCachingService,
    private pageTitleService : PageTitleService,
    private translocoService : TranslocoService,
    private metaTextService : MetaTextService,
  ) {
    this.cloudinary_base_url.set(this.mediaService.cloudinary_base_url);
    this.visualisationFilters.set(this.cultureService.variantFilters);
  }

  isAnythingLoading(){
    for (let something in this.loadingObject()){
      if (this.loadingObject()[something]){
        return true;
      }
    }
    return false;
  }
  getInstructionsOfType(type : string){
    return this.instructions().filter(instruction => instruction.type === type);
  }
  cacheFormData(){
    this.formCachingService.cacheDataItem({
      mainText: this.insightForm.get('mainText').value,
      heading: this.insightForm.get('heading').value,
      rich: this.editorContent,
    },this.slug);
  }
  handleContentChange(content : string){
    debugger;
    this.editorContent = content;
  }
  sendForTranslation(){
    const textsAsObject = {
      mainText: this.insightForm.get('mainText').value,
      heading: this.insightForm.get('heading').value,
      rich: this.editorContent,
    };
    this.getTranslation(textsAsObject,'en-GB',this.selectedLanguage.iso);
  }
  getTranslation(textsAsObject:{[key:string]:string},target_lang : string, source_lang : string) {
    const keys = Object.keys(textsAsObject);
    const values = Object.values(textsAsObject);
    const params = {
      text: values,
      target_lang: target_lang,
      source_lang: source_lang
    };
    return this.translationService.getTranslation(textsAsObject,target_lang,source_lang).subscribe(response => {
      debugger; // recreate the object here
    }, error => {
      debugger;
      // do something with the error
    }
  );
  }

  toggleVisualisationLabelType(){
    this.visualisationLabelType.set(this.visualisationLabelType() === 'number' ? 'name' : 'number');
  }
  toggleShowVisualisationFilters(){
    this.show_visualisation_filters.set(!this.show_visualisation_filters());
  }
  toggleShowInstructionDetails(){
    this.show_instruction_details.set(!this.show_instruction_details());
  }
  toggleShowVisualisationLegend(){
    this.show_visualisation_legend.set(!this.show_visualisation_legend());
  }
  toggleShowVisualisationLegendDetails(){
    this.show_visualisation_legend_details.set(!this.show_visualisation_legend_details());
  }
  // toggleActiveVisualisationFilter(filter : string){
  //   this.active_visualisation_filters.set({...this.active_visualisation_filters(),[filter]:!this.active_visualisation_filters()[filter]});
  // }

  getFallbackFlagUrl (){
    return this.mediaService.fallback_flag_round_url;
  }
  getFlagUrlFromHash (language: Language) : SafeResourceUrl{
    const url = this.mediaService.getFlagUrlFromHash(language?.flag?.hash,'',true,'.png');
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  setEditingLanguage (language: Language, category: string){
    this.selectedLanguage = language;
  }
  showCharacters(characterCount : number){
    this.editorCharacters = characterCount;
  }
  getAllLanguages(freshFromServer: boolean){
    this.loadingObject()['allLanguages'] = true;
    const allLanguagesSubscription = this.languageService.getLanguages(freshFromServer)
      .subscribe(response=> {
        this.allLanguages.set(response);
        this.loadingObject()['allLanguages'] = false;
      });
  }

  getLanguagesAndMyLanguages(freshFromServer: boolean) {
    // this.loadingObject()['languages'] = true;
    this.loadingObject()['myLanguages'] = true;

    combineLatest([
      this.languageService.getLanguages(freshFromServer),
      this.profileUserService.getMyLanguages(freshFromServer)
    ]).subscribe(
      ([languagesResponse, myLanguagesResponse]) => {
        this.allLanguages.set(languagesResponse);
        this.myLanguagesApprovedForEditing.set(myLanguagesResponse.filter(l=>l.skill.admin_approved && l.iso !== 'en').concat([this.englishLanguage()]));
        this.loadingObject()['languages'] = false;
        this.loadingObject()['myLanguages'] = false;
      },
      (error) => {
        this.loadingObject()['languages'] = false;
        this.loadingObject()['myLanguages'] = false;
      }
    );
  }
  
  
  isAdmin (){
    return this.authService.checkRole("Admin");
  }
  submit(){
    if (this.insightForm.invalid){
      return;
    }
    alert('submitted');
  }
  initialiseLanguageChooserForm(){
    this.languageChooserForm = new FormGroup({
      language : new FormControl(null,Validators.required)
    });
    // this.languageChooserForm.valueChanges.subscribe(value=>{
    //   debugger;
    //   this.selectedLanguage = value.language;
    // });
  }
  updateValidity(){
    this.insightForm.get('heading').updateValueAndValidity();
    this.insightForm.updateValueAndValidity();
  }
  getCulturesWithVariants(cultureSlugs : string[]){
    this.loadingObject().variants = true;
    const cultureIds = this.cultures.filter(culture => cultureSlugs.includes(culture.slug)).map(culture => culture.id);
    const culturesSubscription = this.cultureService.getCulturesWithVariants(cultureIds,'geographic','national',false).subscribe((cultures :  Culture[]) => {
      this.culturesWithVariants.set(cultures);
      // this.selectSingleCulture();
      this.loadingObject().variants = false;
    }, error => this.loadingObject().variants = false);

  }
  initialiseCultures(){

    this.loadingObject().cultures = true;
    const culturesSubscription = this.cultureService.getCultures('geographic','national',false).subscribe((cultures :  Culture[]) => {
      this.cultures = cultures;
      // this.selectSingleCulture();
      this.getCulturesWithVariants(this.cultureGroupSlugs);
      this.loadingObject().cultures = false;
    });


  }
  initialiseInsightForm(){

    const cachedData = this.formCachingService.getDataItem(this.slug);
    if (cachedData){
      this.insightForm = new FormGroup({
        heading : new FormControl(cachedData.heading,Validators.required),
        headingTranslation : new FormControl(null,Validators.required),
        mainText : new FormControl(cachedData.mainText,Validators.required),
        richText : new FormControl(cachedData.richText,Validators.required),
        mainTextTranslation : new FormControl(null,Validators.required),
      });
      this.editorContent = cachedData.rich;
    } else {
      this.insightForm = new FormGroup({
        heading : new FormControl(null,Validators.required),
        headingTranslation : new FormControl(null,Validators.required),
        mainText : new FormControl(null,Validators.required),
        richText : new FormControl(null,Validators.required),
        mainTextTranslation : new FormControl(null,Validators.required),
      });
    }

    // this.insightForm = new FormGroup({
    //   heading : new FormControl(null,Validators.required),
    //   headingTranslation : new FormControl(null,Validators.required),
    //   mainText : new FormControl(null,Validators.required),
    //   mainTextTranslation : new FormControl(null,Validators.required),
    // });
    this.insightForm.valueChanges.pipe(
      debounceTime(10000)
    ).subscribe(value => {
      console.log('form changed');
      this.cacheFormData();
    });
    this.insightForm.get('richText').valueChanges.subscribe(value => {
      console.log('rich text changed: '+value);
    } );
  }
  getInstructions(task :Task){
    this.loadingObject().instructions = true;
    const instructionsSubscription = this.metaTextService.getMetaTexts(false,'task',task.id,'content_creation').subscribe(instructions => {
      this.instructions.set(instructions);
      this.loadingObject().instructions = false;
    },error => this.loadingObject().instructions = false);
    this.subscriptions.push(instructionsSubscription);
    
  }
  initialise(){
    this.loadingObject().userAndDelivery = true;
    const deliverySubscription = this.contributionsService.getMyDelivery(this.taskType,'insights', this.slug).subscribe(delivery => {
      this.delivery = delivery;
      this.loadingObject().userAndDelivery = false;
      const insightTitle = this.delivery.insights[0].s;
      const truncatedInsightTitle = this.delivery.insights[0].s?.length > 100 ? this.delivery.insights[0].s.substring(0,85)+'...' : this.delivery.insights[0].s;
      this.pageTitleService.setTitle(truncatedInsightTitle);
      const taskTranslationKey = 'contributions.task.'+this.delivery.task.type;
      const taskTranslation = this.translocoService.translate(taskTranslationKey);
      this.breadcrumbService.setBreadcrumbFragment({urlFragment:'task_type',fragmentName:taskTranslationKey === taskTranslation ? this.delivery.task.type : taskTranslation});
      this.initialiseLanguageChooserForm();
      this.initialiseInsightForm();
      this.initialiseCultures();
      this.getLanguagesAndMyLanguages(false);
      if(!this.delivery.task?.metaTexts){
        this.getInstructions(this.delivery.task);
      } else {
        this.instructions.set(this.delivery.task.metaTexts);
      }
    });
  }
  ngOnInit(): void {
    this.slug = this.route.snapshot.params['slug'];
    this.taskType = this.route.snapshot.params['task_type'];
    if(!this.slug){
      this.router.navigate(['../'],{relativeTo:this.route});
    }
    this.initialise();
    this.userSubscription = this.authService.user.subscribe(user => {
      this.user = user;
      this.isAuthenticated = !!user;
    });
    this.selectedVisualisationFilters.set(this.visualisationFilters().filter(filter => ['class','community','population'].includes(filter.key)));
  }
  ngOnDestroy () : void {
    if (this.userSubscription){
      this.userSubscription.unsubscribe();
    };
    if (this.gallerySubscription){
      this.gallerySubscription.unsubscribe();
    };
    if (this.fileUploadSubscription){
      this.fileUploadSubscription.unsubscribe();
    }
    if (this.languagesSubscription){
      this.languagesSubscription.unsubscribe();
    }
  }

}
