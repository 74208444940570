<div class="visualisation" [style]="'--card-width: '+cardWidth()+'px; --card-height: '+cardHeight()+'px;'">
  @if(filterForm){
  <div class="backgrounds-holder" [style.--cultureDefaultColour]="cultureWithVariants()?.colour ?? '--gray-500'"
    [style.height]="showControls() ? '71%' : '90%'">
    @if(filterForm.get('population')?.value){
    @for(column of geolingualColumns(); track column){
    <div class="column-bg" [ngStyle]="column.styles">
      <div class="floating-element">
        @if(!hideNumberLabels() && labelType() === 'number'){
        <div class="label-round" [ngClass]="{'segment-pointer': highlightMass() && column.variant.mass}">
          {{column.variant.number}}
        </div>

        }
        @if(!hideTextLabels() && labelType() === 'name'){
        <div class="label-pill" [ngClass]="{'segment-pointer': highlightMass() && column.variant.mass}" *transloco="let t; scope:'diversity'">
          @if(column.variant.name_short){
          {{column.variant.name_short}}
          } @else {
          {{t(column.variant.name_short_translation_key)}}
          }
        </div>
        }
      </div>
    </div>
    }
    }
    @if(filterForm.get('class')?.value){
    @for(class of classStrata(); track class){
    <div class="floating-element" [ngStyle]="class.styles">
      @if(!hideNumberLabels() && labelType() === 'number'){
      <div class="label-round" [ngClass]="{'segment-pointer': highlightMass() && class.variant.mass}">
        {{class.variant.number}}
      </div>
      }
      @if(!hideTextLabels() && labelType() === 'name'){
      <div class="label-pill" [ngClass]="{'segment-pointer': highlightMass() && class.variant.mass}" *transloco="let t; scope:'diversity'">
        @if(class.variant.name_short){
        {{class.variant.name_short}}
        } @else {
        {{t(class.variant.name_short_translation_key)}}
        }
      </div>
      }
      <div class="stratum"></div>
    </div>

    }
    }
    @if(filterForm.get('generation')?.value){
    @for(generation of generationStrata(); track generation){
    <div class="floating-element" [ngStyle]="generation.styles">
      @if(!hideNumberLabels() && labelType() === 'number'){
      <div class="label-round" [ngClass]="{'segment-pointer': highlightMass() && generation.variant.mass}">
        {{generation.variant.number}}
      </div>
      }
      @if(!hideTextLabels() && labelType() === 'name'){
      <div class="label-pill"  [ngClass]="{'segment-pointer': highlightMass() && generation.variant.mass}" *transloco="let t; scope:'diversity'">
        @if(generation.variant.name_short){
        {{generation.variant.name_short}}
        } @else {
        {{t(generation.variant.name_short_translation_key)}}
        }
      </div>
      }
      <div class="stratum"></div>
    </div>

    }
    }
    @if(filterForm.get('community')?.value){
    @for(community of communityDots(); track community){
    <div class="floating-element" [ngStyle]="community.styles">
      @if(!hideNumberLabels() && labelType() === 'number'){
      <div class="label-round" [ngClass]="{'segment-pointer': highlightMass() && community.variant.mass}">
        {{community.variant.number}}
      </div>
      }
      @if(!hideTextLabels() && labelType() === 'name'){
      <div class="label-pill"  [ngClass]="{'segment-pointer': highlightMass() && community.variant.mass}" *transloco="let t; scope:'diversity'">
        @if(community.variant.name_short){
        {{community.variant.name_short}}
        } @else {
        {{t(community.variant.name_short_translation_key)}}
        }
      </div>
      }
      <div class="dot"
        [ngStyle]="{height:3 + community.variant.scale * 3 + 'rem',width:3 + community.variant.scale * 3 + 'rem'}">
      </div>
    </div>

    }
    }
  </div>
  @if(showIcons()){
  <div class="icons-holder">
    <i class="fas fa-crown"></i>
    <div class="line"></div>
    <i class="fas fa-users"></i>
    <div class="line"></div>
    <i class="fas fa-user"></i>
  </div>
  }
  <!-- <div class="footer" [ngStyle]="{height: showControls() ? '54%' : '15%'}"> -->
  <div class="footer">
    <!-- <div class="content-holder" [ngStyle]="{top: showControls() ? '20%' : '32%'}"> -->
    <div class="content-holder">
      <div class="title">
        <img
          [src]="(cloudinary_base_url()+'w_16,c_fill,ar_1:1,r_max,f_auto/flags/'+cultureWithVariants().flag?.hash+'.png')"
          [alt]="cultureWithVariants().name">
        <span>{{cultureWithVariants().name}}</span>
      </div>
      @if(showControls()){
      <div>
        <div class="d-flex justify-content-center sub-title">
          @if(selectedVariantFilters()?.length === 1){
          <span class="text-muted"
            *transloco="let t; scope : selectedVariantFilters()[0].translationScope">{{t(selectedVariantFilters()[0].translationKeyPlural)}}</span>
          } @else {
          <span style="opacity: 0;">...</span>
          }
        </div>
        <div class="controls button-group d-flex justify-content-between w-100">
          <button class="btn btn-primary" (click)="previousVariantFilter()">
            <i class="fa-regular fa-chevron-left"></i>
          </button>
          <button class="btn-link-gray px-0" [ngClass]="{'active': showFilters()}" (click)="doToggleShowFilters()">
            <i class="fa-regular fa-filter"></i>
          </button>
          <button class="btn-link-gray px-0" [ngClass]="{'active': filterLoopPlaying()}" (click)="startFilterLoopPlaying()" [disabled]="filterLoopPlaying()">
            <i class="fa-regular fa-play"></i>
          </button>
          <button class="btn-link-gray px-0" [ngClass]="{'active': !filterLoopPlaying()}" (click)="stopFilterLoopPlaying()" [disabled]="!filterLoopPlaying()">
            <i class="fa-regular fa-pause"></i>
          </button>
          <button class="btn btn-primary" (click)="nextVariantFilter()">
            <i class="fa-regular fa-chevron-right"></i>
          </button>
        </div>
      </div>
      }
      @if(variantFilters()){
      <div [collapse]="!showFilters()" [isAnimated]="true">
        <form [formGroup]="filterForm" class="d-flex flex-column gap-2 mt-2">
          <div *ngFor="let filter of variantFiltersOfCurrentCulture()" class="d-flex flex-column">
            <div class="input-group">
              <label *transloco="let t; scope: filter.translationScope" style="cursor: pointer;" class="text-small">
                <input type="checkbox" [formControlName]="filter.key" style="cursor: pointer;" />
                {{ t(filter.translationKeyPlural) }}
              </label>
            </div>
          </div>
        </form>
      </div>
      }
    </div>
  </div>

  }

</div>