export class CultureVariant {
    public id : number;
    public slug: string;
    public parent_culture_id: number;
    public culture_id: number;
    public order: number; // where to appear top to bottom or right to left
    public number: number; // number as a label to be displayed
    public position: number; // 0.75
    public scale: number; // 0.25
    public category: string;
    public type: string;
    public colour: string;
    public mass: boolean;
    public name: string;
    public name_short: string;
    public name_short_translation_key: string;
    public name_translation_key: string;
    public description: string;
    public description_translation_key: string;
}