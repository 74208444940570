 <!-- <h5 class="mb-2" *transloco="let t">{{ selectedMyLanguage() ? t('common.edit') +': '+selectedMyLanguage().name : t('common.add_new')}}</h5>

@if(languageForm){
  <form class="mb-4" [formGroup]="languageForm" (ngSubmit)="onSubmit()" *transloco="let t">
    <div class="form-group">
      <label for="language">{{t('common.language')}}</label>
      <input
        [typeahead]="availableLanguages()"
        formControlName="language"
        [typeaheadMinLength]="0"
        (typeaheadOnSelect)="onNewLanguageSelect($event)"
        class="form-control mt-2"
        typeaheadOptionField="name"
        autocomplete="off"
        [placeholder]="t('common.start_typing')"
        >
      <div *ngIf="languageForm.get('language').invalid && languageForm.get('language').touched" class="text-danger">
        {{t('common.required')}}
      </div>
    </div>
  
    @if(languageForm.get('language').value){
      <div class="form-group mt-4">
        <label for="level">{{t('common.level')}}</label>
        <form-control-lickert-scale-bar
          formControlName="level"
          [steps]="languageSkillLevelRangeInputSteps"
          [min]="languageSkillLevelRangeInputSteps[0].value"
          [max]="languageSkillLevelRangeInputSteps[languageSkillLevelRangeInputSteps.length-1].value"
          [stepSize]="1"
          [volumeControlStyle]="true"
          [minimumOpacity]="0.6"
          >
        </form-control-lickert-scale-bar>
  
        @if(languageForm.get('level').value || languageForm.get('level').value === 0){
          <div class="font-weight-bold" [ngClass]="{'text-muted': languageForm.get('level').disabled}">{{t(languageSkillLevelRangeInputSteps[languageForm.get('level').value].descriptionShortTranslationKey)}}</div>
          <div [ngClass]="{'text-muted': languageForm.get('level').disabled}">{{t('language.level.'+languageForm.get('level').value+'.description')}}</div>
        }
        
        <div *ngIf="languageForm.get('level').invalid && languageForm.get('level').touched" class="text-danger">
          {{t('validation.required')}}
        </div>
      </div>
    
      <div class="form-group">
        <label for="description">{{t('common.description')}}</label>
        <textarea id="description" formControlName="description" class="form-control"></textarea>
        <div class="form-text" *transloco="let t; scope: 'profile'">{{t('profile.my_language_skills_description_info')}}</div>
      </div>
    }
  @if(languageForm.get('language').touched || languageForm.get('level').touched || languageForm.get('description').touched){
    <button class="btn btn-warning mt-3" (click)="resetForm()">{{t('common.cancel_changes')}}</button>
  }
    <button type="submit" class="btn btn-primary mt-3" [disabled]="languageForm.invalid">{{t('common.submit')}}</button>
  </form>
}

<div class="list-group" *transloco="let t">
  @for(language of myLanguages(); track language.id){

    <a (click)="onMyLanguageSelect(language)" class="list-group-item list-group-item-action" [ngClass]="{active: language.id === selectedMyLanguage()?.id}" aria-current="true">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">
          <img class="mr-1" [src]="getFlagUrlFromHash(language.flag?.hash,'w_32,c_fill,ar_1:1,r_max,f_auto/')">
          {{language.name}}</h5>
        @if(language.skill?.level === 0 || language.skill?.level){
          <small *transloco="let t">{{t('language.level.'+language.skill.level+'.name')}}</small>
        }
      </div>
      @if(language.skill?.description){
        <p class="mb-1">{{language.skill.description}}</p>
      }
      @if(language.skill?.level === 0 || language.skill?.level){
        <small *transloco="let t">{{t('language.level.'+language.skill.level+'.description_short')}}</small>
      }
      <div>
        <button class="btn btn-primary" [disabled]="loading()" (click)="onMyLanguageSelect(language)" *transloco="let t">{{t('common.edit')}}</button>
        <button class="btn btn-warning" [disabled]="loading()" (click)="onLanguageRemove($event,language)" *transloco="let t">{{t('common.delete')}}</button>
      </div>
    </a>

    }
</div>
 -->

 <!-- Add New Language Form -->
 <h5 class="mb-3 text-primary" *transloco="let t">
  {{ selectedMyLanguage() ? t('common.edit') + ': ' + selectedMyLanguage().name : t('common.add_new') }}
</h5>

@if(languageForm) {
  <form class="mb-4 rounded" [formGroup]="languageForm" (ngSubmit)="onSubmit()" *transloco="let t">
    <div class="form-group">
      <label for="language" class="form-label">{{ t('common.language') }}</label>
      <input
        [typeahead]="availableLanguages()"
        formControlName="language"
        [typeaheadMinLength]="0"
        (typeaheadOnSelect)="onNewLanguageSelect($event)"
        class="form-control mt-2"
        typeaheadOptionField="name"
        autocomplete="off"
        [placeholder]="t('common.start_typing')"
      />
      <div *ngIf="languageForm.get('language').invalid && languageForm.get('language').touched" class="text-danger">
        {{ t('common.required') }}
      </div>
    </div>

    @if(languageForm.get('language').value) {
      <div class="form-group mt-4">
        <label for="level" class="form-label">{{ t('common.level') }}</label>
        <form-control-lickert-scale-basic
          formControlName="level"
          [steps]="languageSkillLevelRangeInputSteps"
          [min]="languageSkillLevelRangeInputSteps[0].value"
          [max]="languageSkillLevelRangeInputSteps[languageSkillLevelRangeInputSteps.length - 1].value"
          [stepSize]="1"
        ></form-control-lickert-scale-basic>

        @if(languageForm.get('level').value || languageForm.get('level').value === 0) {
          <div class="mt-2">
            <div class="fw-bold text-secondary">
              {{ t(languageSkillLevelRangeInputSteps[languageForm.get('level').value].descriptionShortTranslationKey) }}
            </div>
            <div class="text-muted">
              {{ t('language.level.' + languageForm.get('level').value + '.description') }}
            </div>
          </div>
        }

        <div *ngIf="languageForm.get('level').invalid && languageForm.get('level').touched" class="text-danger">
          {{ t('validation.required') }}
        </div>
      </div>

      <div class="form-group mt-4">
        <label for="description" class="form-label">{{ t('common.description') }}</label>
        <textarea
          id="description"
          formControlName="description"
          class="form-control"
          rows="3"
        ></textarea>
        <div class="form-text text-muted" *transloco="let t; scope: 'profile'">
          {{ t('profile.my_language_skills_description_info') }}
        </div>
      </div>
    }

    @if(languageForm.get('language').touched || languageForm.get('level').touched || languageForm.get('description').touched) {
      <button class="btn btn-warning mt-3 me-2" (click)="resetForm()">{{ t('common.cancel_changes') }}</button>
    }
    <button type="submit" class="btn btn-primary mt-3" [disabled]="languageForm.invalid">{{ t('common.submit') }}</button>
  </form>
}

<!-- Language Cards -->
<div class="list-group" *transloco="let t">
  @for(language of myLanguages(); track language.id) {
    <div class="list-group-item card card-gray-100 p-3 border rounded mb-3">
      <!-- Header Section -->
      <div class="d-flex align-items-center mb-3">
        <img
          class="rounded-circle me-3"
          [src]="getFlagUrlFromHash(language.flag?.hash, 'w_32,c_fill,ar_1:1,r_max,f_auto/')"
          alt="Flag"
          width="40"
          height="40"
        />
        <div class="flex-grow-1">
          <h5 class="mb-1">{{ language.name }}</h5>
          @if(language.skill?.level === 0 || language.skill?.level) {
            <small class="text-muted">{{ t('language.level.' + language.skill.level + '.name') }}</small>
          }
        </div>
      </div>

      <!-- Description Section -->
      @if(language.skill?.description) {
        <p class="mb-2 text-secondary">{{ language.skill.description }}</p>
      }
      @if(language.skill?.level === 0 || language.skill?.level) {
        <small class="d-block text-muted">{{ t('language.level.' + language.skill.level + '.description_short') }}</small>
      }

      <!-- Edit Section -->
      <div class="mt-3">
        <hr>
        <form [formGroup]="languageForm" (ngSubmit)="onSubmit()" *ngIf="selectedMyLanguage()?.id === language.id">
          <div class="form-group">
            <label for="level" class="form-label">{{ t('common.level') }}</label>
            <form-control-lickert-scale-basic
              formControlName="level"
              [steps]="languageSkillLevelRangeInputSteps"
              [min]="languageSkillLevelRangeInputSteps[0].value"
              [max]="languageSkillLevelRangeInputSteps[languageSkillLevelRangeInputSteps.length - 1].value"
              [stepSize]="1"
            ></form-control-lickert-scale-basic>

            @if(languageForm.get('level').value || languageForm.get('level').value === 0) {
              <div class="mt-2">
                <div class="fw-bold text-secondary">
                  {{ t(languageSkillLevelRangeInputSteps[languageForm.get('level').value].descriptionShortTranslationKey) }}
                </div>
                <div class="text-muted">
                  {{ t('language.level.' + languageForm.get('level').value + '.description') }}
                </div>
              </div>
            }
    
            <div *ngIf="languageForm.get('level').invalid && languageForm.get('level').touched" class="text-danger">
              {{ t('validation.required') }}
            </div>
          </div>

          <div class="form-group mt-3">
            <label for="description" class="form-label">{{ t('common.description') }}</label>
            <textarea
              id="description"
              formControlName="description"
              class="form-control"
              rows="2"
            ></textarea>
            <div class="form-text text-muted" *transloco="let t; scope: 'profile'">
              {{ t('profile.my_language_skills_description_info') }}
            </div>
          </div>

          <div class="mt-3 d-flex gap-2 justify-content-end">
            <button class="btn btn-warning" (click)="resetForm()">{{ t('common.cancel_changes') }}</button>
            <button type="submit" class="btn btn-primary" [disabled]="languageForm.invalid">{{ t('common.submit') }}</button>
          </div>
        </form>

        <div *ngIf="selectedMyLanguage()?.id !== language.id" class="d-flex justify-content-end gap-2">
          <button
            class="btn btn-primary"
            [disabled]="loading()"
            (click)="onMyLanguageSelect(language)"
          >
          <i class="fa-solid fa-pen mr-1"></i>
            {{ t('common.edit') }}
          </button>
          <button
            class="btn btn-danger"
            [disabled]="loading()"
            (click)="onLanguageRemove($event, language)"
          >
          <i class="fa-solid fa-trash-can mr-1"></i>
            {{ t('common.delete') }}
          </button>
        </div>
      </div>
    </div>
  }
</div>
