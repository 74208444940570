import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from "@ckeditor/ckeditor5-angular";
import { EditorConfig } from '@ckeditor/ckeditor5-core';
/*
// we don't need to import these. They are built in during our custom build.
import { Markdown } from '@ckeditor/ckeditor5-markdown-gfm';
import { WordCount, WordCountConfig } from '@ckeditor/ckeditor5-word-count'
import { Essentials } from '@ckeditor/ckeditor5-essentials';
*/
import Editor from '../../../../build/ckeditor'; // libs/ckeditor/build/ckeditor



@Component({
  selector: 'multisite-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss']
})
export class CustomBuildBasicRichTextEditorComponent {

  @Output() characters = new EventEmitter<number>();
  @Output() contentChanged = new EventEmitter<string>();

  public Editor = Editor;
  configSimple : EditorConfig & any; //EditorConfig; // RJF 15.3.2024: type of `any` is required here for the wordCount configuration object; the library's types are incorrect, but this wordCount config starts to work if we give it the `any` type here.
  // configReactiveForm : EditorConfig;
  editorDataHtml : string;
  editorDataMarkdown : string;
  // editorDataReactiveForm : string; // default content
  // toolbarSimple: {items:string[]};
  // toolbarReactiveForm: {items:string[]};

  constructor() {

    this.editorDataHtml = "<p>I am the <b>default</b> content for the simple editor</p>";
    this.editorDataMarkdown = "I am the **default** content for the simple editor";
    this.configSimple = {
      language: "de",
      toolbar: ["undo", "heading", "|", "bold", "italic","link", "blockquote", "bulletedList","indent", "numberedList", "|", "trackChanges"],
      placeholder : 'I am the placeholder',
      plugins: [ 
        'BlockQuote', 'Bold', 'Essentials', 'Heading', 'Indent', 'Italic', 'Link', 'List', 'Markdown', 'Paragraph', 'PasteFromOffice', 'Undo', 'WordCount'
        // WordCount,
        /*Markdown*/ // This causes an error if we use a predefined build (ClassicEditor). Use a custom build instead https://ckeditor.com/ckeditor-5/online-builder/. The custom build can be based on the ClassicEditor template.
      ],
      wordCount: {
        onUpdate: (stats : any) => {
            console.log( `Characters: ${ stats.characters }\nWords: ${ stats.words }` ); // nicely strips out the markdown and HTML before counting
            this.characters.emit(stats.characters);
        },
      }
    };

    // this.editorDataReactiveForm = "<p>I am the <b>default</b> content for the reactive form editor</p>";
    // this.configReactiveForm = {
    //   language: "fr",
    //   toolbar: ["heading", "|", "bold", "italic","link", "blockquote", "bulletedList", "numberedList", "|", "trackChanges"],
    //   placeholder : 'I am the placeholder'
    // };
    // this.toolbarReactiveForm = {
    //   items: ["bold", "italic","link", "blockquote", "blockquote", "bulletedList", "numberedList", "|", "trackChanges"]
    // };

    // This is useful:
    // const builtinPluginsObject = Editor.builtinPlugins.map( plugin => plugin.pluginName );
    // console.log(builtinPluginsObject);
  }


  public onEditorChange({ editor }: ChangeEvent, editorType: string) {
    const data = editor.data.get();
    debugger;
    this.contentChanged.emit(data);

    console.log('editor content: ',data);
  }

}
