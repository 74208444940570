import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContributionsHomeComponent } from './home/contributions-home.component';
import { InsightEditComponent } from './insight-edit/insight-edit.component';
import { ComingSoonComponent } from 'libs/shared/src/lib/pages/coming-soon/coming-soon.component';


const routes: Routes = [
  {
    path: '',
    component: ContributionsHomeComponent,
    // data: { label: 'Contributions homepage', translationKey: 'content.contributions', labelSource: null},
  },
  {
    path: 'insights',
    data: { label: 'Insights overview', translationKey: 'content.insights', labelSource: null },
    children: [
      {
        path: '',
        component: ComingSoonComponent,
      },
      {
        path: ':task_type',
        data: { label: 'Task', translationKey: null, labelSource: 'titleService' },
        children: [
          {
            path: '',
            component: InsightEditComponent,
          },
          {
            path: ':slug',
            data: { label: 'Edit Insight', translationKey: null, labelSource: 'titleService' },
            children: [
              {
                path: '',
                component: InsightEditComponent,
              }
            ],
          },
        ],
      },
    ],
  },
  // {
  //   path: 'make',
  //   component: ContributionsHomeComponent,
  //   data: { label: 'Make', translationKey: 'contributions.make', labelSource: null},
  //   children: [
  //     {
  //       path: 'insights',
  //       component: ContributionsHomeComponent,
  //       data: { label: 'Insights', translationKey: 'content.insights', labelSource: null},
  //       children: [
  //         {
  //           path: 'text',
  //           component: ContributionsHomeComponent,
  //           data: { label: 'Write', translationKey: 'content_management.write', labelSource: null},
  //           children: [
  //             {
  //               path: ':slug',
  //               data: { label: 'View or edit', translationKey: 'common.yes', labelSource: 'titleService'},
  //               children: [
  //                 { path: '', component: InsightEditComponent },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  // 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContributionsRoutingModule { }
