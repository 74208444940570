export class RangeInputStep {
   value: any;
   labelText?: string;
   labelTranslationKey?: string;
   labelTranslationScope?: string;
   descriptionText?: string;
   descriptionTranslationKey?: string;
   descriptionTranslationScope?: string;
   descriptionShortTranslationScope?: string;
   descriptionShortText?: string;
   descriptionShortTranslationKey?: string;
   colour?: string;
}