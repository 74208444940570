export class Subject {
    id: number;
    title: string;
    slug: string;
    description?: string;
    category?: string;
    type?: string;

    constructor(
        id: number,
        title: string,
        slug: string,
        description?: string,
        category?: string,
        type?: string
    ) {
        this.id = id;
        this.title = title;
        this.slug = slug;
        this.description = description;
        this.category = category;
        this.type = type;
        
    }
}