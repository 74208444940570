import { MetaText } from "../content";

export class Task {
    id: number;
    slug: string;
    name: any;
    description: any;
    credits_min: number | null;
    credits_max: number | null;
    meta: any | null;
    public metaTexts: MetaText[] | null;
    category: string | null;
    type: string | null;

    constructor(
        id: number,
        slug: string,
        name: any,
        description: any,
        credits_min: number | null,
        credits_max: number | null,
        meta: any | null,
        category: string | null,
        type: string | null,
        metaTexts: MetaText[] | null
    ) {
        this.id = id;
        this.slug = slug;
        this.name = name;
        this.description = description;
        this.credits_min = credits_min;
        this.credits_max = credits_max;
        this.meta = meta;
        this.category = category;
        this.type = type;
        this.metaTexts = metaTexts;

    }

}