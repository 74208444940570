import { Component, input, output } from '@angular/core';
import { MediaService } from '../../content';

import { Delivery } from '../delivery.model';


@Component({
  selector: 'multisite-delivery-status-card',
  templateUrl: './delivery-status-card.component.html',
  styleUrls: ['./delivery-status-card.component.scss']
})
export class DeliveryStatusCardComponent {

  delivery = input.required<Delivery>();
  selected = output<Delivery>();
  cloudinary_base_url : string;

  
  
  constructor(
    private mediaService : MediaService,
  ) {
    this.cloudinary_base_url = this.mediaService.cloudinary_base_url;
  }
  getFlagUrlFromHash(hash:string,transformations : string = ''){
    return this.mediaService.getFlagUrlFromHash(hash,'',true,'.png');
  }
  onButtonClick(){
    this.selected.emit(this.delivery());
  }

}
