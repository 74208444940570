import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControlLickertScaleBasicComponent } from './lickert-scale-basic/form-control-lickert-scale-basic.component';
import { TranslocoModule } from '@jsverse/transloco';
import { FormControlLickertScaleBarComponent } from './lickert-scale-bar/form-control-lickert-scale-bar.component';

@NgModule({
    declarations: [
        FormControlLickertScaleBasicComponent,
        FormControlLickertScaleBarComponent,
    ],
    imports: [
        CommonModule,
        TranslocoModule,
    ],
    exports: [
        FormControlLickertScaleBasicComponent,
        FormControlLickertScaleBarComponent,
    ]
})
export class FormControlsModule { }