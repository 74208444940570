import { Component, forwardRef, input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RangeInputStep } from '../range-input-step.model';

@Component({
  selector: 'form-control-lickert-scale-basic',
  templateUrl: './form-control-lickert-scale-basic.component.html',
  styleUrls: ['./form-control-lickert-scale-basic.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlLickertScaleBasicComponent),
      multi: true
    }
  ]
})
export class FormControlLickertScaleBasicComponent implements ControlValueAccessor {

  steps = input.required<RangeInputStep[]>();
  min = input.required<number>();
  max = input.required<number>();
  stepSize = input.required<number>();

  value: any;
  isDisabled: boolean = false;

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onLabelClick(value: any): void {
    this.value = value;
    this.onChange(this.value);
    this.onTouched();
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.value = input.value;
    this.onChange(this.value);
    this.onTouched();
  }
}