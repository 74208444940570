import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContributionsHomeComponent } from './home/contributions-home.component';
import { ContributionsRoutingModule } from './contributions-routing.module';
import { LoadersModule } from '../loaders';
import { TranslocoModule } from '@jsverse/transloco';
import { AvatarsModule, LinksModule, MultilingualFormsModule, PaginationModule, PillsModule, UploadersModule } from '@frontend/shared';
import { DeliveryStatusCardComponent } from './delivery-status-card/delivery-status-card.component';
import { InsightEditComponent } from './insight-edit/insight-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { RichTextEditorBasicCustomBuildModule, RichTextEditorReactiveFormInputModule } from '@frontend/ckeditor';
import { ProgressIndicatorDoughnutModule } from '../tracking';
import { NationalCultureVisualisationModule } from '../cultures/national-culture-visualisation';

@NgModule({
    declarations: [
        ContributionsHomeComponent,
        DeliveryStatusCardComponent,
        InsightEditComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TypeaheadModule,
        CollapseModule,
        ContributionsRoutingModule,
        LoadersModule,
        TranslocoModule,
        LinksModule,
        PaginationModule,
        AvatarsModule,
        MultilingualFormsModule,
        UploadersModule,
        PillsModule,
        RichTextEditorBasicCustomBuildModule, // Our own library
        RichTextEditorReactiveFormInputModule,
        FormsModule,
        ProgressIndicatorDoughnutModule,
        NationalCultureVisualisationModule,
    ],
    exports: [
        // ContributionsHomeComponent,
    ]
})
export class ContributionsModule { }