import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FormCachingService {

  key_prefix: string = 'form_draft_'; // 'form_draft_new_snapshot'
  expiry_prefix: string = 'expiry_'; // 'expiry_new_snapshot'

  constructor() {

    this.clearAllData(true);
  }

  clearData(){
    this.clearAllData(false);
  }

  clearAllData(onlyExpired:boolean){
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      const now = Date.now();
      if (key && key.startsWith(this.key_prefix)) {
        let unprefixed_key = key.substring(this.key_prefix.length);
        let expiry : number = +localStorage.getItem(this.expiry_prefix+unprefixed_key);
        if(!onlyExpired || (onlyExpired && now > expiry)){
          this.clearDataItem(unprefixed_key);
        }
      }
    }
  }

  cacheDataItem(data: any, key : string, expiry : number = null): void {
    if(!data || !key ){return;}
    debugger;
    expiry = expiry ?? Date.now() + (24 * 60 * 60 * 1000); // by default 24 hours in the future
    localStorage.setItem(this.key_prefix+key, JSON.stringify(data));
    localStorage.setItem(this.expiry_prefix+key, expiry.toString());
  }
  getDataItem(key : string): any {
    const savedData = localStorage.getItem(this.key_prefix+key);
    return savedData && key ? JSON.parse(savedData) : null;
  }
  clearDataItem(key : string): void {
    localStorage.removeItem(this.key_prefix+key);
    localStorage.removeItem(this.expiry_prefix+key);
  }



}
