import { Component, OnChanges, OnInit, SimpleChanges, computed, effect, input, output, signal } from '@angular/core';
import { MediaService } from '../../content';
import { Language } from '../language.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RangeInputStep } from '../../forms';
import { LanguageSkill } from '../language-skill.model';
import { TranslocoService } from '@jsverse/transloco';
import { LanguageService } from '../language.service';

@Component({
  selector: 'multisite-user-language-editor',
  templateUrl: './user-language-editor.component.html',
  styleUrl: './user-language-editor.component.scss'
})
export class UserLanguageEditorComponent implements OnInit, OnChanges {

  allLanguages = input.required<Language[]>();
  myLanguages = input<Language[] | undefined>(undefined);
  error = input<string|{message:string,meta:any}|undefined>(undefined);
  loading = input<boolean>();
  onSaveLanguage = output<LanguageSkill>();
  onDeleteLanguage = output<Language>();
  languageForm : FormGroup;
  availableLanguages = computed(() => {
    return this.allLanguages() ?  (!this.myLanguages()?.length ? this.allLanguages() : this.allLanguages().filter(language => !(this.myLanguages().some(myLanguage => myLanguage.id === language.id) && language.id !== this.selectedMyLanguage()?.id))) : [];
  });
  selectedMyLanguage = signal<Language | undefined>(undefined);
  editingNewLanguage = computed(() => {
    return this.myLanguages() && this.selectedMyLanguage() && this.myLanguages().find(lang => this.selectedMyLanguage()?.id === lang.id);
  });
  currentLevelDescriptionShort


  constructor(private mediaService : MediaService, private translocoService : TranslocoService, private languageService : LanguageService
  ) {

    effect(() => {
       if (this.myLanguages()) {
          this.resetForm();
        }
    },{
      allowSignalWrites: true,
    });

    
  }

  get languageSkillLevelRangeInputSteps() : RangeInputStep[]{
    return this.languageService.languageSkillLevelRangeInputSteps;
  };

  getFlagUrlFromHash(hash:string,transformations : string = ''){
    return this.mediaService.getFlagUrlFromHash(hash,transformations,true,'.png');
  }

  onMyLanguageSelect(language : Language){
    if(this.loading()){ return; }
    if(this.selectedMyLanguage()?.id === language.id){ return; };
    this.languageForm.reset();
    this.selectedMyLanguage.set(language);
    this.languageForm.patchValue({
      language: language.name,
      level: language.skill?.level,
      description: language.skill?.description
    });
  }
  onNewLanguageSelect(event : {item: Language}){
    this.selectedMyLanguage.set(event.item ? event.item : undefined);
    this.languageForm.patchValue({
      level: null,
      description: ''
    });
  }

  onSubmit(){
    if (this.languageForm.invalid) {
      return;
    }
    const language = this.selectedMyLanguage();
    const level = +this.languageForm.value.level;
    const description = this.languageForm.value.description;
    const languageSkill = new LanguageSkill(language.id, level, description); 
    this.onSaveLanguage.emit(languageSkill);
  }

  onLanguageRemove(event, language : Language){
    event.stopPropagation();
    const translation = this.translocoService.translate('common.permanent_are_you_sure');
    if (confirm(translation)) {
      this.resetForm();
      this.onDeleteLanguage.emit(language);
    }
  }
  resetForm(){
    this.languageForm.reset();
    this.selectedMyLanguage.set(undefined);
  }

  initialiseForm(){
    this.languageForm = new FormGroup({
      language: new FormControl(null, Validators.required),
      level: new FormControl(undefined, Validators.required),
      description: new FormControl(undefined, [Validators.minLength(2), Validators.maxLength(200)]),
    });

    this.languageForm.get('language')?.valueChanges.subscribe(selectedMyLanguage => {
      if(!selectedMyLanguage){ return; }
      const language = this.myLanguages().find(lang => lang.id === selectedMyLanguage.id);
      if (language) {
      this.languageForm.patchValue({
        level: language.skill?.level,
        description: language.skill?.description
      });
      }
    });
    this.languageForm.get('level')?.valueChanges.subscribe(level => {
      if (isNaN(level)) {
        return;
      }
      if (typeof level !== 'number') {
        level = parseInt(level, 10);
      }
    });
  }

  ngOnInit(){
    this.initialiseForm();
  }
  ngOnChanges(changesObject:SimpleChanges){
    if(changesObject.loading && this.languageForm){
      if(changesObject.loading.currentValue){
        this.languageForm.get('level')?.disable();
      } else {
        this.languageForm.get('level')?.enable();
      }
    }
  }

}
