<div class="card my-3">
  <div class="card-body">
    <h5 class="card-title">
      {{delivery().insights?.[0]?.s}}
        <span class="icon-holder pull-right">
          @if(delivery().task.type === 'write'){
            <i class="fa-solid fa-pen"></i>
          } @else if (delivery().task.type === 'video') {
            <i class="fa-solid fa-video"></i>
          }
        </span>
    </h5>
    <h6 class="card-subtitle mb-2 text-body-secondary">
      @if (delivery().insights?.[0]?.cultures) {
        <!-- <img [class]="'mr-1'" [src]="getFlagUrlFromHash(delivery().insights?.[0]?.cultures[0].flag?.hash, 'w_40,c_fill/')"> -->
        <multisite-avatar-pill *ngFor="let culture of delivery().insights?.[0]?.cultures" [removalMode]="false" [active]="false"
          [fallbackTranslationKey]="'tracking.missing'" [cursorNone]="true" [clickable]="false"
          [imageUrl]="cloudinary_base_url+'w_40,c_fill,ar_1:1,r_max,f_auto/flags/'+culture.flag?.hash+'.png'"
          [text]="culture.name">
        </multisite-avatar-pill>
      }
      {{delivery().subject?.title}}
    </h6>
    <p class="card-text">{{delivery().task?.description}}</p>
    <p class="card-text">{{delivery().subject?.description}}</p>
    <!-- <p class="card-text">{{delivery().task?.name}}</p> -->
  
    <a (click)="onButtonClick()" class="btn btn-primary" *transloco="let t">{{t('common.go')}} <i class="pl-2 fa-solid fa-arrow-right"></i></a>
  </div>
  <div class="card-footer">
    <span>Max credits: {{delivery().task?.credits_max}}</span>
    <span class="pull-right">Status: {{delivery().started_at ? 'Started':'Not started'}}</span>
  </div>

</div>