import { Injectable, Signal, WritableSignal, signal } from '@angular/core';
import { Delivery } from './delivery.model';
import { PaginatedDeliveries } from './paginated-deliveries.model';
import { Task } from './task.model';
import { Subject } from './subject.model';
import { Insight } from './insight.model';
import { Observable, Subscription, catchError, map, of, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { DataProcessingService } from '../utilities/data-processing.service';
import { LanguageService } from '../language';

interface Report {
  name: string;
  allowed_filters: string[];
  allowed_options: string[];
  url: string;
}
export interface ReportData {
  report: Report;
  data: { [key: string]: any };
  filters: { [key: string]: any };
  options: { [key: string]: any };
  requested_at: Date;
}
export type SavedReportFiltersAndOptions = {
  filters: { [key: string]: any },
  options: { [key: string]: any },
  settings: { [key: string]: any }
};

@Injectable({
  providedIn: 'root'
})
export class ContributionsService {

  reports: Report[] = [];
  deliveries: Delivery[] = []; // only a few standalone deliveries, downloaded individually on their on page
  reportsData: ReportData[] = [];
  reportFilters: { [key: string]: any } = {};
  reportOptions: { [key: string]: any } = {};
  reportSettings: { [key: string]: any } = {}; // only for the frontend, never sent to the backend
  savedReportFiltersAndOptions: SavedReportFiltersAndOptions[] = [];
  activeLanguageSubscription: Subscription;
  private _languageSlugsAllowedForTextContentCreation = [
    'en',
    'fr',
  ];


  constructor(
    private http: HttpClient,
    private router: Router,
    private dataProcessingService: DataProcessingService,
    private languageService: LanguageService,
  ) { 

    this.reports = [
      {
          name: "myPaginatedDeliveries",
          allowed_filters: [],
          allowed_options: ['items_per_page', 'sort_by', 'sort_order', 'include_expired', 'page'],
          url: 'api/v1/deliveries/my-paginated-deliveries',
      },
    ];
    this.activeLanguageSubscription =
      this.languageService.activeLanguageObject.subscribe(() => {
        this.clearData();
        // this.getData('myPaginatedDeliveries').subscribe(); // This causes all deliveries to be loaded every time the application boots
    });

  }

  get languageSlugsAllowedForTextContentCreation(){
    return this._languageSlugsAllowedForTextContentCreation;
  }
  clearData() {
    const reportNames = this.reports.map(report => report.name);
    this.clearReportData(reportNames);
    this.clearDeliveries();
  }
    // generateFakeDeliveries(): Delivery[] {
    //   const deliveries: Delivery[] = [];
    //   for (let i = 0; i < 10; i++) {
    //     deliveries.push({
    //       id: i + 1,
    //       task: new Task(i+1, 'slug' + (i + 1), 'name' + (i + 1), 'description' + (i + 1), Math.floor(Math.random() * 100), Math.floor(Math.random() * 100), null, null, null),
    //       subject: new Subject(i+1, 'title' + (i + 1), 'slug' + (i + 1), 'description' + (i + 1), 'category' + (i + 1), 'type' + (i + 1)),
    //       insight: new Insight(i+1, 'slug' + (i + 1), 's' + (i + 1), 'm' + (i + 1), 'l' + (i + 1), Math.floor(Math.random() * 100), new Date(), 'category' + (i + 1), 'type' + (i + 1), null, new Date(), new Date(), new Date(), null),
    //       source_lang: 'en',
    //       target_lang: 'fr',
    //       user_id: Math.floor(Math.random() * 1000),
    //       handler_id: Math.floor(Math.random() * 1000),
    //       started_at: new Date(),
    //       submitted_at: null,
    //       reopen_count: Math.floor(Math.random() * 5),
    //       abandoned_at: null,
    //       completed_at: null,
    //       credits: Math.floor(Math.random() * 100),
    //       cultures: [],
    //       topics: []
    //     });
    //   }
    //   return deliveries;
    // }

    // generateFakePaginatedDeliveries(): PaginatedDeliveries {
    //   const deliveries = this.generateFakeDeliveries();
    //   return {
    //     data: deliveries,
    //     links: {
    //       first: 'http://first',
    //       last: 'http://last',
    //       prev: 'http://prev',
    //       next: 'http://next'
    //     },
    //     meta: {
    //       current_page: 1,
    //       from: 1,
    //       last_page: 10,
    //       path: 'http://path',
    //       per_page: 10,
    //       to: 10,
    //       total: 100
    //     }
    //   };
    // };

    

    clearReportData(reportNames: string[]): boolean {
      const foundCachedReportsData = this.reportsData.filter(reportData => reportNames.includes(reportData.report.name));
      this.reportsData = this.reportsData.filter(reportData => !(reportNames.includes(reportData.report.name)));

      return foundCachedReportsData.length > 0;
    };
    clearDeliveries(): void {
      this.deliveries = [];
    }

  copyReportData(reportData: ReportData) { // so that the user can change the filters without changing the cached data

      return Object.assign({}, reportData, {
          filters: reportData.filters ? JSON.parse(JSON.stringify(reportData.filters)) : null,
          options: reportData.options ? JSON.parse(JSON.stringify(reportData.options)) : null,
      });

  }
  filterTheFilters(filters, allowedFilters) {

      return Object.keys(filters)
          .filter(key => allowedFilters.includes(key))
          .reduce((filtered, key) => {
              filtered[key] = filters[key];
              return filtered;
          }, {});

  };
  getCachedReportDataIndex(reportsDataToFilter: ReportData[], reportName: string, filters: { [key: string]: any }, options: { [key: string]: any }): number {

    return reportsDataToFilter.findIndex(reportData => {

        if (
            reportData.report.name === reportName &&
            ((!reportData.filters && !filters) || this.dataProcessingService.matchTwoComplexObjects(reportData.filters, filters)) &&
            ((!reportData.options && !options) || this.dataProcessingService.matchTwoComplexObjects(reportData.options, options))
        ) {
            return true;
        }
        return false;
    });
};
getCachedReportDataFiltered(reportsDataToFilter: ReportData[], reportName: string, filters: { [key: string]: any }, options: { [key: string]: any }): ReportData[] {

    return reportsDataToFilter.filter(reportData => {

        if (
            reportData.report.name === reportName &&
            ((!reportData.filters && !filters) || this.dataProcessingService.matchTwoComplexObjects(reportData.filters, filters)) &&
            ((!reportData.options && !options) || this.dataProcessingService.matchTwoComplexObjects(reportData.options, options))
        ) {
            return true;
        }
        return false;
    });
};
deliveryFilter(deliverable_type: string, task_type: string, queryParams: any = {}) {
  return (delivery: any) => {
    return (
      delivery[deliverable_type]?.length > 0 &&
      delivery.task?.type === task_type &&
      (queryParams && Object.keys(queryParams).length > 0
        ? Object.keys(queryParams).every(key => delivery[key] === queryParams[key])
        : true)
    );
  };
}
private findCachedDelivery(task_type: string /* e.g. 'create' */, deliverable_type: string /* e.g. 'insights' */, deliverable_slug:string, queryParams : {[key:string]: any}): Delivery {
  const myPaginatedDeliveriesFlattened = this.reportsData
    .filter(reportData => reportData.report.name === 'myPaginatedDeliveries')
    .reduce((acc, reportData) => acc.concat(reportData.data.data), [])
    .filter(this.deliveryFilter(deliverable_type, task_type,queryParams)); // has possible duplicates because a delivery may appear in different sorts and filters of the paginated data

  const standaloneDeliveries = this.deliveries.filter(this.deliveryFilter(deliverable_type, task_type, queryParams));

  const allDeliveries = myPaginatedDeliveriesFlattened.concat(standaloneDeliveries);

  return allDeliveries.find(delivery => delivery[deliverable_type].map(deliveryType => deliveryType.slug).includes(deliverable_slug)); // The first duplicate is good enough
}
private cachedDelivery(delivery : Delivery, task_type: string /* e.g. 'create' */, deliverable_type: string /* e.g. 'insights' */, deliverable_slug:string, queryParams : {[key:string]: any}): Delivery {

  const standaloneDeliveryIds = this.deliveries.filter(this.deliveryFilter(deliverable_type, task_type, queryParams)).map(delivery => delivery.id);

  standaloneDeliveryIds.forEach(standaloneDeliveryId => {
      const cachedDeliveryIndex = this.deliveries.findIndex(delivery => delivery.id === standaloneDeliveryId);
      this.deliveries[cachedDeliveryIndex] = delivery;
    });

  if(!standaloneDeliveryIds.length){
    this.deliveries.push(delivery);
  }
  return delivery;
}
getMyDelivery(
  task_type: 'create' | 'review' | 'translate', 
  deliverable_type: 'insights' | 'something', 
  deliverable_slug: string, 
  queryParams?: { [key: string]: any }
): Observable<Delivery> {

  const cachedDelivery = this.findCachedDelivery(task_type, deliverable_type, deliverable_slug, queryParams);

  if (cachedDelivery) {
      return of(cachedDelivery);
  }

  let url = 'api/v1/deliveries/my-delivery';
  const params : {task_type : string, deliverable_type:string, deliverable_slug:string} = {task_type, deliverable_type, deliverable_slug};

  // debugger;
  // if(params.deliverable_type === 'insights'){
  //   params.deliverable_type = 'insight'; // the backend expects the singular form
  // }
  if (queryParams) {
    const queryString = Object.keys(queryParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
      .join('&');
    url += `?${queryString}`;
  }

  return this.http.post<{data:Delivery}>(url,params)
      .pipe(
          map((response) => {
            const delivery = response.data as Delivery;
              return this.cachedDelivery(delivery, task_type, deliverable_type, deliverable_slug, queryParams); 
          }),
          catchError(error => this.handleError(error))
      );
}
updateDelivery(delivery: Delivery): Observable<Delivery> {
  // TODO
  // remember to delete all cached reports and standalone deliveries when updating any delivery
  return of(delivery);
}
cacheReportData(reportName: string, filters: { [key: string]: any }, options: { [key: string]: any }, data: { [key: string]: any }): ReportData {
  let existingReportDataIndex = this.getCachedReportDataIndex(this.reportsData, reportName, filters, options);
  let reportData = {
      report: this.reports.find(report => report.name === reportName),
      requested_at: new Date(),
      filters: filters,
      options: options,
      data: data,
  };
  if (existingReportDataIndex > -1) {
      this.reportsData[existingReportDataIndex] = reportData;
  } else {
      this.reportsData.push(reportData);
  }
  return reportData;

};
    getData(reportName: string, filters: { [key: string]: any } | null = {}, options: { [key: string]: any } | null = {}, freshFromServer: boolean = false): Observable<ReportData> {

      if (filters === null) {
          filters = {};
      }
      if (options === null) {
          options = {};
      }

      let report = this.reports.find(report => report.name === reportName);

      if (!report) {
          throw 'Report not found';
      }

     
      if (report.allowed_filters) {
          filters = Object.keys(filters).reduce((filtered, key) => {
              if (report.allowed_filters.includes(key)) {
                  filtered[key] = filters[key];
              }
              return filtered;
          }, {});
      }
      if (report.allowed_options) {
          options = Object.keys(options).reduce((filtered, key) => {
              if (report.allowed_options.includes(key)) {
                  filtered[key] = options[key];
              }
              return filtered;
          }, {});
      }

      let cachedDataIndex = this.getCachedReportDataIndex(this.reportsData, reportName, filters, options);

      if (!freshFromServer && cachedDataIndex > -1) {
          this.reportsData[cachedDataIndex].requested_at = new Date();
          return of(this.copyReportData(this.reportsData[cachedDataIndex]));
      } else {

          let params: { [key: string]: any } = {};

          params.filters = filters;
          params.options = options;
          // for (let key in options) {
          //     params[key] = options[key];
          // }


          return this.http.post<ReportData>(report.url, params)

              .pipe(
                  map((response) => {

                      return this.copyReportData(this.cacheReportData(reportName, filters, options, response));
                  }),
                  catchError(error => this.handleError(error))
              );

      }
  };
  getReportConfig(reportName: string): Report {
    return this.reports.find(report => report.name === reportName);
};
  getRequestFilters(reportName: string, savedReportSettings: SavedReportFiltersAndOptions): { [key: string]: any } {
      let request_filters = {};
      let report = this.getReportConfig(reportName);
      if (report && report.allowed_filters && savedReportSettings.filters) {
          report.allowed_filters.forEach(allowed_filter => {
              if (savedReportSettings.filters[allowed_filter]) {
                  if (
                      savedReportSettings.filters[allowed_filter].from ||
                      savedReportSettings.filters[allowed_filter].to ||
                      (savedReportSettings.filters[allowed_filter].from === undefined && savedReportSettings.filters[allowed_filter].to === undefined)) {

                      request_filters[allowed_filter] = savedReportSettings.filters[allowed_filter];
                  }

              }
          });
      }
      return request_filters;
  };
  getRequestOptions(reportName: string, savedReportSettings: SavedReportFiltersAndOptions): { [key: string]: any } {
      let request_options = {};
      let report = this.getReportConfig(reportName);
      if (report && report.allowed_options && savedReportSettings.options) {
          report.allowed_options.forEach(allowed_option => {
              if (savedReportSettings.options[allowed_option]) {
                  //   if( 
                  //     savedReportSettings.options[allowed_option].from ||
                  //     savedReportSettings.options[allowed_option].to ||
                  //     (savedReportSettings.options[allowed_option].from === undefined && savedReportSettings.options[allowed_option].to === undefined)){

                  //       request_options[allowed_option] = savedReportSettings.options[allowed_option];
                  //   }
                  request_options[allowed_option] = savedReportSettings.options[allowed_option];

              }
          });
      }
      return request_options;
  };
    private handleError(errorResponse: HttpErrorResponse) {
      let errorMessage = 'error.something_went_wrong';
      if (!errorResponse.error || !errorResponse.error.message) {
        return throwError(errorMessage);
      }
      switch (errorResponse.error.message) {
        case 'This action is unauthorized.':
          errorMessage = 'error.permissions_lacking';
          break;
        case 'Unauthenticated.':
          this.router.navigate(['/login']); // TODO - consider handling this by route guard instead (when we have solved the problem in route guard's timeout that slow get-user responses will redirect to login without waiting for an eror)
          break;
        case 'User not found':
          errorMessage = 'error.not_found_must_be_registered';
          break;
        case 'Only the current owner can set a new owner':
          errorMessage = 'error.permissions_lacking';
          break;
        case '400 Duplicate - already has this role in this hub':
          errorMessage = 'error.no_change_user_has_role_aready';
          break;
        case '403 Removing this role from the owner is not allowed':
          errorMessage = 'hubs.cannot_remove_owners_manager_role';
          break;
        case 'The given data was invalid.':
          if (
            errorResponse.error.errors?.file_url?.[0] ==
            'validation.ends_with_extension'
          ) {
            errorMessage = 'error.file_type';
          } else {
            errorMessage = 'error.data_invalid';
          }
          break;
        default:
        // no action needed. We already set the default error message.
      }
      if (errorResponse.error.meta){
        return throwError({message:errorMessage,meta:errorResponse.error.meta});
      }
      return throwError(errorMessage);
    }
}

