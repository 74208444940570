<div class="row flex-lg-row-reverse process-visualisation-promo">
  <div class="m-0 p-0">
    <div class="row justify-content-start">
      @for (step of steps(); track step; let last = $last;){
      <div class="card-step flex-grow-1 col-12 col-sm-6 col-lg-3 align-items-start {{
          step.divClasses
        }}" [ngClass]="{'custom-background': step.divClasses}">
        <div class="row gap-2">
          @if(step.metaText?.weight === 0 || step.metaText?.weight ||
          step.metaText?.num === 0 || step.metaText?.num){
          <div class="symbol-top">
            <progress-indicator-doughnut [elementWidth]="100" [fillClass]="'traffic-light-6'" [progressPercent]="
                step.metaText.weight === null && step.metaText.num
                  ? (step.metaText.num / steps()?.length) * 100
                  : 100
              " [icon]="step.metaText.icon" [text]="
                step.metaText.icon
                  ? null
                  : step.metaText.num === 0 || step.metaText.num
                  ? step.metaText.num
                  : step.metaText.weight
              ">
            </progress-indicator-doughnut>
          </div>
          } @else if(step.media) {
          <img class="w-64 mt-2 rounded symbol-top" [src]="
              step.media.asset_url && thumbnailTransformations()
                ? step.media.base_url +
                  thumbnailTransformations() +
                  step.media.asset_url
                : step.media.file_url
            " [alt]="step.media.description ?? 'Step symbol'" />
          } @else if(step.metaText?.icon.identifier_1) {
          <div [ngClass]="{'icon-holder': addBackgroundToStandaloneIcons()}" class=" mr-1 symbol-top">
            <i [class]="step.metaText.icon.identifier_1 + ' fa-3x symbol-top'"
              *ngIf="step.metaText.icon.identifier_1"></i>
          </div>

          } @else if(step.metaText?.icon.identifier_2) {
          <img [src]="
              cloudinary_base_url() +
              thumbnailTransformations() +
              step.metaText.icon.identifier_2 +
              '.svg'
            " class="mr-1 symbol-top" />
          }
          <div class="card-text text-wrap" *transloco="let t">
            <p class="title">{{ step.metaText.text }}</p>
            @if(step.subtitleText||step.subtitleTranslationKey){
            <p class="subtitle">
              {{ step.subtitleText ?? t(step.subtitleTranslationKey) }}
            </p>
            } @if (step.date){
            <p class="subtitle">{{ step.date | date: 'longDate' }}</p>
            }
          </div>
        </div>
      </div>
      }
    </div>
  </div>
</div>