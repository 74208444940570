export class LanguageSkill {
    public language_id: number; // 71
    public level: number; // 6
    public description: string; // 'Grew up in Spain'
    public admin_approved: boolean; // true
    constructor(language_id: number, level: number, description: string, admin_approved: boolean = null) { 
        this.language_id = language_id;
        this.level = level;
        this.description = description;
        this.admin_approved = admin_approved;
    }
}