import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, map } from 'rxjs/operators';
import { ErrorService } from '../error';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,

  ) {

  }

  getTranslation(textsAsObject:{[key:string]:string},target_lang : string, source_lang : string) {

    const keys = Object.keys(textsAsObject);
    const values = Object.values(textsAsObject);

    const params = {
      text: values,
      target_lang: target_lang,
      source_lang: source_lang
    };

    return this.http.post<any>('api/v1/translate', params)
      .pipe(
        map((response) => {
          debugger; // recreate the object here
            return response;
        }),
        catchError(error => this.handleError(error))
    );
  }
  private handleError(errorResponse: HttpErrorResponse) {
    let errorMessage = 'error.something_went_wrong';
    if (!errorResponse.error || !errorResponse.error.message) {
      return throwError(errorMessage);
    }
    // if (errorResponse.error.errors?.slug?.[0] === 'The slug has already been taken.'){
    //   errorMessage ="content_management.slug_availability_error";
    //   return throwError(errorMessage);
    // }
    const message = errorResponse.error.message;
    const standardErrorMessageTranslationKey = this.errorService.getCommonErrorMessageTranslationKey(message);
    if(standardErrorMessageTranslationKey){
      errorMessage = standardErrorMessageTranslationKey;
    }
    if (errorResponse.error.meta){
      return throwError({message:errorMessage,meta:errorResponse.error.meta});
    }
    return throwError(errorMessage);
  }
}
