<div class="container" *transloco="let t">
  <div class="row">
      
      <div class="col-md-8 p-2">
          <p *ngIf="!isAnythingLoading() && !paginatedDeliveries?.data.length"><span>{{t('common.none_yet')}}</span></p>
          <multisite-loader-globe-animation-detail *ngIf="loadingObject.initialData" [width]="100" [height]="100">
          </multisite-loader-globe-animation-detail>

          <multisite-delivery-status-card
          *ngFor="let delivery of paginatedDeliveries?.data"
          [delivery]="delivery"
          (selected)="gotoDelivery($event)"

          ></multisite-delivery-status-card>
  
          <div class="my-3">
              <frontend-pagination-type-three
                  *ngIf="paginatedDeliveries?.meta && paginatedDeliveries.meta.last_page > 1"
                  [classes]="'pg-purple justify-content-center'" [loading]="isAnythingLoading()"
                  [meta]="paginatedDeliveries.meta" [lastPage]="paginatedDeliveries.meta.last_page"
                  [links]="paginatedDeliveries.links" (changePage)="doGetPaginatedDeliveries($event, false)">
              </frontend-pagination-type-three>
          </div>
          
      </div>
      <div class="col-md-4 p-2">

          <multisite-link-type-six *ngFor="let link of links"
              [imageTransformations]="'w_100,c_thumb/'"
              [titleText]="link.label"
              [description]="link.description"
              [imageUrl]="link.media?.base_url"
              [media]="link.media"
              [cta_label]="link.cta_label"
              [cta_type]="link.cta_type"
              [url]="link.url"
          >
          </multisite-link-type-six>
      </div>
  </div>
</div>