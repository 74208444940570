import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, MediaService } from '@frontend/common';

import { User } from '@frontend/common';
import { MainNavigationLink } from '@frontend/common';

@Component({
  selector: 'lib-profile-user-home',
  templateUrl: './profile-user-home.component.html',
  styleUrls: ['./profile-user-home.component.scss']
})
export class ProfileUserHomeComponent implements OnInit, OnDestroy {

  user: User = null;
  userSubscription;
  isAuthenticated: boolean = false;
  loading = false;
  error = null;
  navigationLinks: MainNavigationLink[];

  constructor(
    private authService: AuthService,
    private mediaService: MediaService,
    private router : Router,
    private route : ActivatedRoute,
  ) { 
    this.navigationLinks = [
      // TODO: investigate why Angular treats routerLinkValue: ['password'] as a primitive string, not as an array of strings
      {labelText: null, labelLanguageKey : 'common.name', routerLinkValue: ['name'], condition: ''},
      {labelText: null, labelLanguageKey : 'common.languages', routerLinkValue: ['languages'], condition: ''},
      {labelText: null, labelLanguageKey : 'content_management.images', routerLinkValue: ['images'], condition: ''},
      // {labelText: null, labelLanguageKey : 'authentication.password', routerLinkValue: ['password'], condition: ''},
      // {labelText: null, labelLanguageKey : 'common.email_address', routerLinkValue: ['email'], condition: ''}, 
    ];
  }
  getDefaultAvatar (){
    return this.mediaService._fallback_avatar_url;
  }
  gotoPictureEdit(){
    this.router.navigate(['/profile/images']);
  }

  changeEmail (newEmail: string){
    this.loading = true;
    this.authService.changeEmail(newEmail).subscribe(
      () => {
        this.loading = false;
      },
      errorMessage => {
        this.loading = false;
        this.error = errorMessage == 'authentication.already_registered_log_in' ? 'authentication.already_registered' : errorMessage;
      });
  };

  ngOnInit() {
    this.userSubscription = this.authService.user.subscribe(
      (user) => {
        this.user = user;
      },
      (error) => {
        this.error = error;
      }
    );
    this.authService.getUser(true).subscribe(); // gets the full editable version, with email address  
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

}

