import { Component, OnInit, WritableSignal, signal, input, computed, output, OnChanges, SimpleChanges, effect } from '@angular/core';
import { Culture } from '../../culture.model';
import { CultureService, CultureVariant, CultureVariantFilter, DataProcessingService, MediaService } from '@frontend/common';
import { FormControl, FormGroup } from '@angular/forms';

interface CultureVariantWithStyles {
  variant: CultureVariant;
  styles: {
    [key: string]: any;
  };
  
}

@Component({
  selector: 'multisite-national-culture-visualisation',
  templateUrl: './national-culture-visualisation.component.html',
  styleUrls: ['./national-culture-visualisation.component.scss']
})
export class NationalCultureVisualisationComponent implements OnInit, OnChanges {

  thisYear = new Date().getFullYear();
  cultureWithVariants = input.required<Culture>();
  showIcons = input<boolean>(false);
  cardWidth = input<number>(240);
  cardHeight = input<number>(400);
  showControls = input<boolean>(true);
  showFilters = input<boolean>(false);
  autoPlayFilterLoop = input<boolean>();
  filterLoopPlaying = signal<boolean>(false);
  togglePlayFilterLoop = output<void>();
  private autoPlayIntervalId: any;
  hideNumberLabels = input.required<boolean>();
  hideTextLabels = input.required<boolean>();
  highlightMass = input<boolean>();
  labelType = input<string>('number');
  filterForm : FormGroup;
  variantFilters = signal<CultureVariantFilter[] | undefined>(undefined);
  variantFiltersOfCurrentCulture = computed(() => {
    return this.variantFilters()?.filter(filter => this.cultureWithVariants().variants.some(variant => variant.category === filter.category && variant.type === filter.type));
  });
  selectedVariantFiltersDefault = input<CultureVariantFilter[] | undefined>(undefined);
  selectedVariantFiltersDynamic = input<CultureVariantFilter[] | undefined>(undefined); // for instances of the visualisations which continually receive updates to the filters but do not output any updates 

  selectedVariantFilters = signal<CultureVariantFilter[] | undefined>(undefined); 
  toggleShowFilters = output<void>();
  updateSelectedVariantFilters = output<CultureVariantFilter[]>();

  birthYearOfOldestGeneration = computed(() : number => {
    return this.cultureWithVariants().variants.filter(variant => variant.category === 'generation').map(variant => variant.position).reduce((a,b) => Math.min(a,b),Infinity);
  });
  yearsSinceBirthYearOfOldestGeneration = computed(() : number => {
    const result = this.thisYear - this.birthYearOfOldestGeneration();
    return result;
  });
  
  geolingualColumns = computed(() : CultureVariantWithStyles[] => {
    return this.makeElements('geolingual','column');
  });
  classStrata = computed(() : CultureVariantWithStyles[] => {
      return this.makeElements('class','stratum');
  });
  generationStrata = computed(() : CultureVariantWithStyles[] => {
      return this.makeElements('generation','stratum');
  });
  communityDots = computed(() : CultureVariantWithStyles[] => {
      return this.makeElements('class','community');
  });
  cloudinary_base_url : WritableSignal<string> = signal<string>(null);


  constructor(private mediaService : MediaService, private cultureService : CultureService, private dataProcessingService : DataProcessingService) {
    this.cloudinary_base_url.set(this.mediaService.cloudinary_base_url);
    this.variantFilters.set(this.cultureService.variantFilters);
    effect(() => { 
     const uniqueFilterKeys = this.variantFiltersOfCurrentCulture()?.map(filter => filter.key).filter((value, index, self) => self.indexOf(value) === index);
     const formControlKeys = Object.keys(this.filterForm?.controls);
     if(!this.filterForm || !this.dataProcessingService.matchTwoArrays(uniqueFilterKeys, formControlKeys)){
       this.initialiseFilterForm();
     }
    });
  }

  makeElements(category:string,type:string){
    return this.cultureWithVariants().variants.filter(variant => variant.category === category && variant.type === type).sort((a,b) => a.position - b.position).map(variant => {
      return {
        variant: variant,
        styles: type === 'community' ? this.getVariantDotStyles(variant) : type === 'column' ? this.getVariantColumnStyles(variant) : type === 'stratum' ? (category === 'generation' ? this.getVariantGenerationStratumStyles(variant) : this.getVariantStratumStyles(variant)) : this.getVariantStratumStyles(variant)
      }
    });
  };

  getVariantStratumStyles(variant : CultureVariant) : {[key : string] : any} {
    return {
      '--top': (variant.position * 100) + '%',
      '--height': (variant.scale * 100) + '%',
      'position': 'absolute',
      'width': '120%',
    };
  }
  getVariantGenerationStratumStyles(variant : CultureVariant) : {[key : string] : any} {
    const result = {
      '--top': ((this.yearsSinceBirthYearOfOldestGeneration() - (this.thisYear - variant.position))/this.yearsSinceBirthYearOfOldestGeneration()) * 100 + '%',
      '--height': ((variant.scale/this.yearsSinceBirthYearOfOldestGeneration()) * 100) + '%',
      'position': 'absolute',
      'width': '120%',
      // 'transform': 'translateY(0.5rem)'
    };
    return result;
  }
  getVariantDotStyles(variant : CultureVariant) : {[key : string] : any} {
    // convert 50.8 to 0.5 for x-axis and 0.8 for y-axis
    const xPosition = Math.floor(variant.position) / 100; // Integer part for x-axis, converted to decimal
    const yPosition = variant.position - Math.floor(variant.position); // Fractional part for y-axis
    return {
      'left': (xPosition * 100) + '%',
      'top': (yPosition * 100) + '%',
      '--top': (yPosition * 100) + '%',
      'position': 'absolute',
    };
  }
  getVariantColumnStyles(variant : CultureVariant) : {[key : string] : any} {
    return {
      'background-color': variant.colour ?? this.cultureWithVariants().colour,
      'left': (variant.position * 100) + '%',
      'width': (variant.scale * 100) + '%',
    };
  }
  doToggleShowFilters(){
    this.toggleShowFilters.emit();
  }

  startFilterLoopPlaying() {
    if (this.filterLoopPlaying()) { return; }
    this.filterLoopPlaying.set(true);
    this.autoPlayIntervalId = setInterval(() => {
      this.nextVariantFilter();
    }, 10000);
  }

  stopFilterLoopPlaying() {
    this.filterLoopPlaying.set(false);
    if (this.autoPlayIntervalId) {
      clearInterval(this.autoPlayIntervalId);
      this.autoPlayIntervalId = null;
    }
  }

  initialiseFilterForm(){

    const selectedVariantFilters = this.selectedVariantFilters() ?? this.selectedVariantFiltersDynamic() ?? this.selectedVariantFiltersDefault() ?? [];

    const filters = this.variantFiltersOfCurrentCulture() ? this.variantFiltersOfCurrentCulture().map(filter => filter.key) : [];
    const selectedFilters = selectedVariantFilters.map(filter => filter.key);
    const controls = {};
    filters.forEach(filter => {
      controls[filter] = new FormControl(selectedFilters.includes(filter));
    });
    this.filterForm = new FormGroup(controls);
    this.filterForm.valueChanges.subscribe(value => {
      this.selectedVariantFilters.set(this.variantFiltersOfCurrentCulture().filter(filter => value[filter.key]));
      this.updateSelectedVariantFilters.emit(this.selectedVariantFilters());
    });
    this.updateSelectedVariantFilters.emit(selectedVariantFilters); // Not sure why this is not emitted by the valueChanges subscription the first time the form is initialised
  }
  patchFilterForm(activeFilterKeys : string[] = []){
    if(!this.filterForm){ return; }
    let formControlObejct = this.variantFiltersOfCurrentCulture()
    .reduce((acc, filter) => {
      acc[filter.key] = activeFilterKeys.includes(filter.key);
      return acc;
    }, {})
    this.filterForm.patchValue(formControlObejct);
  }
  resetFilterForm(){
    if(!this.filterForm){ return; }
    this.filterForm.reset({}, { emitEvent: false });
  };
  findLatestActiveFilterKey(): string{
    if(!this.variantFiltersOfCurrentCulture()?.length || !this.filterForm){ return null; }
    const formControlKeys = Object.keys(this.filterForm.controls);
    let lastFilterIndex = 0;
    formControlKeys.forEach((key, index) => {
      if (this.filterForm.get(key).value) {
      lastFilterIndex = index;
      }
    });
    return formControlKeys[lastFilterIndex];
  }
  findFirstActiveFilterKey(): string{
    if(!this.variantFiltersOfCurrentCulture()?.length || !this.filterForm){ return null; }
    const formControlKeys = Object.keys(this.filterForm.controls);
    let firstFilterIndex = formControlKeys.findIndex(key => this.filterForm.get(key).value);
    if(firstFilterIndex === -1){
       firstFilterIndex = 0;
    }
    return formControlKeys[firstFilterIndex];
  }
  nextVariantFilter(cancelAutoPlay : boolean = false){
    let latestFilterKey = this.findLatestActiveFilterKey();
    if(!latestFilterKey || !this.variantFiltersOfCurrentCulture()?.length){ return; }
    let index = this.variantFiltersOfCurrentCulture().map(filter => filter.key).indexOf(latestFilterKey);
    if (index < this.variantFiltersOfCurrentCulture().length - 1) {
      index++;
    } else {
      index = 0;
    }
    this.patchFilterForm([this.variantFiltersOfCurrentCulture()[index].key]);
  }
  previousVariantFilter(){
    let firstFilterKey = this.findFirstActiveFilterKey();
    if(!firstFilterKey || !this.variantFiltersOfCurrentCulture()?.length){ return; }
    let index = this.variantFiltersOfCurrentCulture().map(filter => filter.key).indexOf(firstFilterKey);
    if (index > 0 ) {
      index--;
    } else {
      index = this.variantFiltersOfCurrentCulture().length - 1;
    }
    this.patchFilterForm([this.variantFiltersOfCurrentCulture()[index].key]);
  }

  ngOnInit() {
    this.initialiseFilterForm();
  }
  ngOnChanges(changesObject: SimpleChanges): void {
    if(changesObject.selectedVariantFiltersDefault?.currentValue && this.filterForm && changesObject.selectedVariantFiltersDefault.isFirstChange()){
        const filters = this.selectedVariantFilters().map(filter => filter.key);
        this.resetFilterForm();
        this.patchFilterForm(filters);
    }
    if(changesObject.selectedVariantFiltersDynamic?.currentValue){
      const filters = this.selectedVariantFiltersDynamic().map(filter => filter.key);
      this.resetFilterForm();
      this.patchFilterForm(filters);
    }
    if(changesObject.autoPlayFilterLoop?.currentValue){
      this.startFilterLoopPlaying();
    };
  }

}
