import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Hub, HubService } from '..';
import { BreadcrumbService } from '../../navigation/breadcrumb/breadcrumb.service';


@Component({
  selector: 'multisite-hub-marketing',
  templateUrl: './hub-marketing.component.html',
  styleUrls: ['./hub-marketing.component.scss']
})
export class HubMarketingComponent implements OnInit, OnDestroy {

  hubSubscription: Subscription;
  loading: boolean;
  hubSlug: string;
  hub: Hub;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private hubService: HubService,
    private breadcrumbService : BreadcrumbService,
    // private authService : AuthService,
    // private galleryService : GalleryService,
    // private languageService : LanguageService,
  ) {}

  
  // getGallery (category,type){
  //   this.gallerySubscription = this.galleryService.getGallery (category,type)
  //     .subscribe(
  //       response => {
  //         if (response){
  //           this.gallery = response;
  //         }
  //       },
  //       error => {
  //         // this.error = error;
  //       }
  //     );
  // };
  // getLanguages(){
  //   this.languagesSubscription = this.languageService.getLanguages()
  //     .subscribe(response=> {
  //       this.languages = response;
  //       this.englishLanguage = this.languages.find(l=>l.iso === 'en');
  //       if (!this.multilingualMetaTextProfileGeneralDraft){
  //         this.languageVersions['profile_general'] = [this.englishLanguage];
  //       } else {
  //         this.languageVersions['profile_general'] = this.preSelectLanguageVersionsFromDraft(this.multilingualMetaTextProfileGeneralDraft, this.languages);
  //       }
  //     });
  // }
  // get selectedProfileGeneralLanguageName (){
  //   return this.selectedLanguages['profile_general']?.name
  // }
  // set selectedProfileGeneralLanguageName (value){
  //   const foundLanguage = this.languages?.find(l=>l.name == value);
  //   if (this.selectedLanguages['profile_general'] && foundLanguage) {
  //     this.selectedLanguages['profile_general'] = foundLanguage;
  //   };
  // }
  // onLanguageSelect($event : {item:Language}, category: string){
  //   if(!$event?.item){return;};
  //   this.selectedLanguages[category] = $event?.item;
  //   if (this.languageVersions[category] && !this.languageVersions[category].some(lang=>lang.iso === $event.item.iso)){
  //     this.languageVersions[category].push($event.item);
  //   }
  //   this['language_form_'+category].reset();
  //   this.selectedLanguages[category] = null;
  // }
  // onLanguageRemove(language : Language, category: string){
  //   if (language.iso === this.englishLanguage.iso){
  //     return;
  //   }
  //   this.languageVersions[category] = this.languageVersions[category].filter(lang=>lang.iso !== language.iso);
  // }
  // cancelUploadFile (bool : boolean){
  //   this.fileUploadSuccess = null;
  //   if (this.fileUploadSubscription){
  //     this.fileUploadSubscription.unsubscribe();
  //   }
  //   for (let category in this.uploadProgress){
  //     for (let type in this.uploadProgress[category]){
  //       this.uploadProgress[category][type] = null;
  //     }
  //   }
  // }
  // uploadFile (formData : FormData,category,type){
  //   this.fileUploadSuccess = null;
  //   this.fileUploadError = null;
  //   if (!this.hub || !formData){return;};
  //   formData.append('category',category);
  //   formData.append('type',type);
  //   const upload$ = this.hubService.uploadFile(this.hub.slug,formData).pipe(
  //     finalize(() => {
  //       // Code to run when the subscription is finalized (cancelled or completed)
  //       // For example, you can reset any relevant properties or perform cleanup tasks
  //     })
  //   );
  //   this.uploadProgress[category] = {[type]:0};
  //   this.fileUploadSubscription = upload$.subscribe(event => {
  //     if (event.type == HttpEventType.UploadProgress) {
  //       this.uploadProgress[category][type] = Math.round(100 * (event.loaded / event.total));
  //     } else if (event.type === HttpEventType.Response) {
  //       this.fileUploadSuccess = 'common.saved';
  //       this.uploadProgress[category][type] = null;
  //       this.getHub(this.hub?.slug,true);
  //     }
  //   }, error=>{
  //     this.fileUploadError = error;
  //       this.uploadProgress[category][type] = null;
  //   });
  // }
  // deepCopyMultilingualMetaTexts(metaTexts) {
  //   return JSON.parse(JSON.stringify(metaTexts));
  // }
  // getMultilingualMetaTexts (hub:Hub){
  //   this.multilingualMetaTextsSubscription = this.hubService.getMultilingualMetaTexts(hub).subscribe(response=>{
  //     if (response){
  //       this.hub = response;
  //       if (this.hub?.multilingualMetaTexts?.length){
  //         this.multilingualMetaTextsDraft = this.deepCopyMultilingualMetaTexts(this.hub.multilingualMetaTexts);
  //         this.multilingualMetaTextProfileGeneralDraft = this.multilingualMetaTextsDraft.find(mt=>mt.category==='profile'&&mt.type==='general');
  //         if (this.languages){
  //           this.languageVersions['profile_general'] = this.preSelectLanguageVersionsFromDraft(this.multilingualMetaTextProfileGeneralDraft, this.languages);
  //         }
  //       }
  //     }
  //   })
  // }
  // preSelectLanguageVersionsFromDraft(metaTexts: MetaTextMultilingual, languages : Language[]){
  //   let languageVersions : Language[] = [];
  //   let columns = ['s','m','l','xl'];
  //   columnLoop:
  //   for (let column of columns){
  //     if (metaTexts[column]){
  //       languageVersions = languages.filter(l=>Object.keys(metaTexts[column]).includes(l.iso));
  //       break columnLoop;
  //     }
  //   }
  //   return languageVersions.find(l=>l.iso === this.englishLanguage.iso) ? languageVersions : languageVersions.concat(this.englishLanguage);
  // }
  // updateMultilingualMetaTexts (alias:string, draft_texts_id: number, newTexts : FormGroup[], category:string, type:string){
  //   this.loadingMetaTextUpdate = true;
  //   let backendValues : MultilingualMetaTextsContent[] = newTexts.map(fc=> fc.value);
  //   this.multilingualMetaTextsSubscription = this.hubService.setMultilingualMetaTexts(this.hub,draft_texts_id,backendValues,category,type,alias).subscribe(response=>{
  //     if (response?.multilingualMetaTexts){
  //       this.multilingualMetaTextsDraft = this.deepCopyMultilingualMetaTexts(response.multilingualMetaTexts);
  //       this.multilingualMetaTextProfileGeneralDraft = this.multilingualMetaTextsDraft.find(mt=>mt.category==='profile'&&mt.type==='general');
  //       if (this.languages){
  //         this.languageVersions['profile_general'] = this.preSelectLanguageVersionsFromDraft(this.multilingualMetaTextProfileGeneralDraft, this.languages);
  //       }
  //       this.loadingMetaTextUpdate = false;
  //     }
  //   },
  //   error => {
  //     this.loadingMetaTextUpdate = false;

  //   })
  // }
  getHub (slug : string, updating : boolean){
    this.hubService.getManagedHub(slug).subscribe(response => {
      this.hub = response;
      if (!updating){
        this.breadcrumbService.setBreadcrumbFragment({urlFragment:'hub',fragmentName:this.hub.name});
        // this.getMultilingualMetaTexts(this.hub);
      }
    })
  }

  ngOnInit(): void {
    this.route.url.subscribe(() => {
      let fullUrlParts = this.router.routerState.snapshot.url
        .toLowerCase()
        .split('/')
        .filter((s) => s);
      this.getHub(fullUrlParts[1], false);
    });
    // this.userSubscription = this.authService.user.subscribe(user => {
    //   this.user = user;
    //   this.isAuthenticated = !!user;
    // });
    // this.getGallery('profile','banner');
    // this.getLanguages();
  }
  ngOnDestroy () : void {
    // if (this.userSubscription){
    //   this.userSubscription.unsubscribe();
    // };
    // if (this.gallerySubscription){
    //   this.gallerySubscription.unsubscribe();
    // };
    if (this.hubSubscription){
      this.hubSubscription.unsubscribe();
    }
    // if (this.fileUploadSubscription){
    //   this.fileUploadSubscription.unsubscribe();
    // }
    // if (this.languagesSubscription){
    //   this.languagesSubscription.unsubscribe();
    // }
    // if (this.multilingualMetaTextsSubscription){
    //   this.multilingualMetaTextsSubscription.unsubscribe();
    // }
  }

}
