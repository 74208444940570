<div class="container">
  <div class="sidenav-second-level my-5">
    <div class="row gap-4 gap-lg-0">
      <div class="col-lg-4">
        <multisite-vertical-menu-type-one
        [navigationLinks]="navigationLinks"
        [pictureEditable]="true"
        (pictureEdit)="gotoPictureEdit()"
        [nameText]="user ? user.fname+' '+user.lname : '[No name]'"
        [picture]="user?.picture ? user.picture : getDefaultAvatar()"
        >
        </multisite-vertical-menu-type-one>
      </div>
      <div class="col-lg-8">

        <router-outlet></router-outlet>
        
      </div>
    </div>
  </div>
</div>