<div
  class="container mb-5 pt-4"
  *transloco="let t"
>

  <div class="row">
    <div class="col-md-7">

      <div>
        <h4>Choose your preferred language for creating</h4>
        <multisite-avatar-pill
          *ngFor="let language of myLanguagesApprovedForEditing()"
          (selected)="setEditingLanguage(language, 'master')"
          [fallbackImageUrl]="getFallbackFlagUrl()"
          [fallbackTranslationKey]="'tracking.missing'"
          [cursorNone]="isAnythingLoading()"
          [imageUrl]="getFlagUrlFromHash(language)"
          [active]="selectedLanguage?.iso === language.iso"
          [translationKey]="language.name"
          [removalMode]="false"
          [clickable]="!isAnythingLoading()"
        >
          >
        </multisite-avatar-pill>
      </div>

      <div class="editing-card mt-4">
        <div class="card">
          <div class="card-body">

            @if(insightForm){
            <form
              [formGroup]="insightForm"
              role="form"
              class="d-flex flex-column w-100"
              *transloco="let t"
            >

            <multisite-form-control-rich-text-editor
              [formControlName]="'richText'"
            ></multisite-form-control-rich-text-editor>

              <div class="input-self">

                <label class="form-label h3 fw-bold">
                  {{t('content_management.heading')}}
                </label>
                <p
                  class="mb-2"
                  *transloco="let t; scope:'contributions'"
                >{{t('contributions.heading_help')}}</p>

                <div
                  class="input-group textarea-control mb-3"
                  [ngClass]="{
                    'ng-invalid':
                      insightForm.get('heading')?.invalid &&
                      (insightForm.get('heading')?.touched || insightForm.get('heading')?.dirty),
                    'ng-valid':
                      insightForm.get('heading')?.valid &&
                      insightForm.get('heading')?.touched &&
                      insightForm.get('heading')?.dirty
                  }"
                >

                  <span class="input-group-text">
                    {{selectedLanguage?.name}}
                  </span>

                  <input
                    class="form-control"
                    type="text"
                    [disabled]="isAnythingLoading()"
                    [formControlName]="'heading'"
                    [placeholder]="'Write a nice heading here'"
                  />

                  <!-- <textarea *ngIf="rows" class="form-control" [rows]="rows" [disabled]="loading"
                      [formControlName]="getControlLanguageProperty(insightForm.get('heading')"
                      [id]="getControlLanguageProperty(insightForm.get('heading')"
                      [placeholder]="placeholderTranslationKey ? t(placeholderTranslationKey) : placeholderText"
                      (change)="updateValidity()">
                    </textarea> -->

                  <div
                    *ngIf="
                      insightForm.get('heading')?.errors?.maxlength && insightForm.get('heading')?.touched
                    "
                    class="invalid-feedback"
                  >
                    {{ t('common.too_long') }}
                  </div>

                  <div
                    *ngIf="
                      insightForm.get('heading')?.errors?.minlength && insightForm.get('heading')?.touched
                    "
                    class="invalid-feedback"
                  >
                    {{ t('common.too_short') }}
                  </div>

                  <div
                    *ngIf="
                      insightForm.get('heading')?.errors?.required &&
                      insightForm.get('heading')?.dirty
                    "
                    class="invalid-feedback"
                  >
                    {{ t('common.required') }}
                  </div>

                  <div
                    *ngIf="error"
                    class="text-danger"
                  >
                    {{ t(error) }}
                  </div>

                </div>

              </div>
              <div class="input-self">

                <label class="form-label h3 fw-bold">
                  {{t('content_management.main_text')}}
                </label>
                <p
                  class="mb-2"
                  *transloco="let t; scope:'contributions'"
                >{{t('contributions.insight.main_text_help')}}</p>

                <div
                  class="input-group textarea-control mb-3"
                  [ngClass]="{
                    'ng-invalid':
                      insightForm.get('main_text')?.invalid &&
                      (insightForm.get('main_text')?.touched || insightForm.get('main_text')?.dirty),
                    'ng-valid':
                      insightForm.get('main_text')?.valid &&
                      insightForm.get('main_text')?.touched &&
                      insightForm.get('main_text')?.dirty
                  }"
                >

                  <span class="input-group-text">
                    {{selectedLanguage?.name}}
                  </span>


                  <textarea
                    class="form-control"
                    [rows]="5"
                    [disabled]="isAnythingLoading()"
                    [formControlName]="'mainText'"
                    [id]="'mainText'"
                    [placeholder]="'Write something good here'"
                  >
                    </textarea>

                  <div
                    *ngIf="
                      insightForm.get('mainText')?.errors?.maxlength && insightForm.get('mainText')?.touched
                    "
                    class="invalid-feedback"
                  >
                    {{ t('common.too_long') }}
                  </div>

                  <div
                    *ngIf="
                      insightForm.get('mainText')?.errors?.minlength && insightForm.get('mainText')?.touched
                    "
                    class="invalid-feedback"
                  >
                    {{ t('common.too_short') }}
                  </div>

                  <div
                    *ngIf="
                      insightForm.get('mainText')?.errors?.required &&
                      insightForm.get('mainText')?.dirty
                    "
                    class="invalid-feedback"
                  >
                    {{ t('common.required') }}
                  </div>

                  <div
                    *ngIf="error"
                    class="text-danger"
                  >
                    {{ t(error) }}
                  </div>

                </div>

              </div>

              <button
                [disabled]="!insightForm.valid || isAnythingLoading()"
                (click)="submit()"
                class="btn btn-primary align-self-end"
                type="button"
                id="button-addon2"
              >
                <multisite-loader-globe-animation-detail
                  *ngIf="isAnythingLoading()"
                  [width]="24"
                  [height]="24"
                >
                </multisite-loader-globe-animation-detail>
                {{t('common.submit')}}
              </button>

            </form>
            }

          </div>
        </div>
      </div>

      <div class="editing-card mt-4">
        <label class="form-label h3 fw-bold">
          {{t('content_management.main_text')}}
        </label>
        <p
          class="mb-2"
          *transloco="let t; scope:'contributions'"
        >{{t('contributions.insight.main_text_help')}}</p>
        <p
          class="mb-2 text-muted"
          *transloco="let t; scope:'contributions'"
        >{{t('contributions.insight.main_text_helpX')}}</p>

        <section>
          <div><h4>Editor content</h4>
            <p>{{editorContent}}</p>
          </div>
          <div class="my-5">
            <multisite-rich-text-editor
              (characters)="showCharacters($event)"
              (contentChanged)="handleContentChange($event)"
              >
            </multisite-rich-text-editor>

          </div>
          <p>Character count = {{editorCharacters}}</p>
          <progress-indicator-doughnut
            [progressPercent]="(editorCharacters/200)*100"
            [elementWidth]="5"
          ></progress-indicator-doughnut>
        </section>

        <div><button class="btn btn-primary" (click)="sendForTranslation()">Translate</button></div>
      </div>
    </div>
    <div class="col-md-5">

      @if(loadingObject().variants){
        <multisite-loader-globe-animation-detail [width]="100" [height]="100"></multisite-loader-globe-animation-detail>
      }

      <multisite-national-culture-visualisation-parent
        [culturesWithVariants]="culturesWithVariants()"
        [showIcons]="false"
        [cardHeight]="visualisationCardHeight()"
        [cardWidth]="visualisationCardWidth()"     
        [labelType]="visualisationLabelType()"
        [highlightMass]="highlightMassCultureItemsInVisualisation()"
        (toggleLabelType)="toggleVisualisationLabelType()"
        [selectedVariantFiltersDefault]="selectedVisualisationFilters()"
        [show_legend]="show_visualisation_legend()"
        [show_legend_details]="show_visualisation_legend_details()"
        (toggleShowLegend)="toggleShowVisualisationLegend()"
        (toggleShowLegendDetails)="toggleShowVisualisationLegendDetails()"
        [showFilters]="show_visualisation_filters()"
        [showControls]="show_visualisation_controls()"
        (toggleShowFilters)="toggleShowVisualisationFilters()"
        [autoPlayFilterLoop]="autoPlayFilterLoop()"
      ></multisite-national-culture-visualisation-parent>


      <!-- <h5>Debugging</h5>
      <p>
      <b>instructionTypes()</b>: {{instructionTypes() |json}}<br>
      </p> -->
      @if(loadingObject().instructions){
        <multisite-loader-globe-animation-detail [width]="100" [height]="100"></multisite-loader-globe-animation-detail>
      }

      @if(instructions()?.length){
        <div class="mt-2">
          <h4 *transloco="let t">{{t('common.instructions')}}</h4>
          <a (click)="toggleShowInstructionDetails()"
          class="text-nowrap link-primary-dark fw-bold d-flex align-items-center gap-2">{{
          show_instruction_details()
          ? t('common.show_less')
          : t('common.show_more')
          }}
          <span *ngIf="!show_instruction_details()"><i class="fa-solid fa-plus"></i></span>
          <span *ngIf="show_instruction_details()"><i class="fa-solid fa-minus"></i></span>
        </a>
  
          @for(instructionType of instructionTypes(); track instructionType){
            <div class="row">
  
              <div class="col-12 mt-4">
                <h5 *transloco="let t; scope: 'contributions'">{{t('contributions.instruction.'+instructionType)}}</h5>
              </div>
  
              <div class="col-12">
                  @for(instruction of getInstructionsOfType(instructionType); track instruction.id){
                    <div class="list-box-item">
                      <div class="content-wrap">
                        <div class="icon-holder">
                          <i class="fa-solid {{instruction.icon?.identifier_1 ?? 'fa-check'}} icon-size"></i>
                          <span class="splitter"></span>
                        </div>
                        <div class="content">
                          <strong>{{ instruction.s }}</strong>
                          <div [collapse]="!show_instruction_details()" [isAnimated]="true">{{ instruction.m }}</div>
                        </div>
                      </div>
                    </div>
  
                  <!-- <div class="list-box-item" *ngFor="let instruction of instructions">
                    <div class="content-wrap">
                      <div class="icon-holder">
                        <i class="fa-solid {{instruction.icon?.identifier_1 ?? 'fa-check'}} icon-size"></i>
                        <span class="splitter"></span>
                      </div>
                      <div class="content">
                        <strong>{{ instruction.s }}</strong><span *ngIf="instruction.m"><br />{{ instruction.m }}</span>
                      </div>
                    </div>
                  </div> -->
  
  
                }
                </div>
            </div>
          }
        </div>
      }

    </div>
  </div>
</div>