import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileUserHomeComponent } from './profile-user-home/profile-user-home.component';
import { ProfileUserLanguagesComponent } from './languages/profile-user-languages.component';
import { ProfilePictureSettingsComponent } from './profile-picture-settings/profile-picture-settings.component';
import { ProfileUserNameComponent } from './profile-user-name/profile-user-name.component';

const routes: Routes = [
  {
    path: '',
    component: ProfileUserHomeComponent,
    children : [
      { path: '', redirectTo: 'name', pathMatch: 'full'},
      { path: 'name', component: ProfileUserNameComponent, data: { label: 'My name', translationKey: 'common.name', labelSource: null},},
      { path: 'languages', component: ProfileUserLanguagesComponent,  data: { label: 'LanguageXX', translationKey: 'common.languages', labelSource: null}},
      { 
        path: 'images', 
        data: { label: 'My picture', translationKey: 'content_management.images', labelSource: null},
        children: [
          {path : '',
          component: ProfilePictureSettingsComponent,}
        ]
      },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileUserRoutingModule { }
