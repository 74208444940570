import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormInputRichTextEditorComponent } from './rich-text-editor.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule } from '@angular/forms';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CKEditorModule,
  ],
  declarations: [
    ReactiveFormInputRichTextEditorComponent
  ],
  exports: [
    ReactiveFormInputRichTextEditorComponent
  ],
})
export class RichTextEditorReactiveFormInputModule { }
