import { Component, OnInit } from '@angular/core';
import { WindowService } from '@frontend/common';

@Component({
  selector: 'multisite-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {

  constructor(
    private windowService: WindowService,
  ) { }

  ngOnInit(): void {
    this.windowService.goToTop()
  }

}
