import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { finalize } from 'rxjs/operators';

import { Hub, HubService, MultilingualMetaTextsContent } from '..';
import { AuthService, User } from '../../auth';
import { BreadcrumbService } from '../../navigation/breadcrumb/breadcrumb.service';
import { Gallery, GalleryService } from '../../content/media';
import { Language, LanguageService } from '../../language';
import { MetaTextMultilingual } from '../../content/metaText-multilingual.model';
import { FormGroup, NgForm } from '@angular/forms';


@Component({
  selector: 'multisite-hub-profiles',
  templateUrl: './hub-profiles.component.html',
  styleUrls: ['./hub-profiles.component.scss']
})
export class HubProfilesComponent implements OnInit, OnDestroy {

  hubSubscription: Subscription;
  loadingMetaTextUpdate: boolean;
  loading: boolean;
  hub: Hub;
  error = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private hubService: HubService,
    private breadcrumbService : BreadcrumbService,
    private authService : AuthService,
    private galleryService : GalleryService,
    private languageService : LanguageService,
  ) {}


  getHub (slug : string, updating : boolean){
    this.hubService.getManagedHub(slug).subscribe(response => {
      this.hub = response;
      if (!updating){
        this.breadcrumbService.setBreadcrumbFragment({urlFragment:'hub',fragmentName:this.hub.name});
      }
    })
  }

  ngOnInit(): void {
    this.route.url.subscribe(() => {
      let fullUrlParts = this.router.routerState.snapshot.url
        .toLowerCase()
        .split('/')
        .filter((s) => s);
      this.getHub(fullUrlParts[1], false);
    });
         
  }
  ngOnDestroy () : void {
 
    if (this.hubSubscription){
      this.hubSubscription.unsubscribe();
    }
  }

}
