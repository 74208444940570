import { Component, computed, input, output, signal } from '@angular/core';
import { Culture } from '../../culture.model';
import { CultureVariantFilter } from '../../culture.service';

@Component({
  selector: 'multisite-national-culture-visualisation-parent',
  templateUrl: './national-culture-visualisation-parent.component.html',
  styleUrls: ['./national-culture-visualisation-parent.component.scss']
})
export class NationalCultureVisualisationParentComponent {

  variantFilters = signal<CultureVariantFilter[] | undefined>(undefined);
  selectedVariantFiltersDefault = input<CultureVariantFilter[] | undefined>(undefined);
  selectedVariantFilters = signal<CultureVariantFilter[] | undefined>(undefined);

  culturesWithVariants = input.required<Culture[]>();
  selectCulture = output<Culture>();
  selectedCultureId = input<number>();
  selectedCulture = computed(() => {
    const selectedCultureId = this.selectedCultureId() || this.culturesWithVariants()[0]?.id;
    return this.culturesWithVariants().find(culture => culture.id === selectedCultureId);
  });
  culturesWithVariantsSubGroup = computed(() => {
    return this.culturesWithVariants().filter(culture => culture.id !== this.selectedCulture()?.id);
  });
  selectedVariants = computed(() => {
    if(!this.selectedCulture()){ return; };
    if(!this.selectedVariantFilters()){ return this.selectedCulture().variants; };
    return this.selectedCulture().variants.filter(variant => 
      this.selectedVariantFilters().some(filter => 
      variant.category === filter.category && variant.type === filter.type
      )
    );
  });
  selectedMassVariants = computed(() => {
    return this.selectedVariants() ? this.selectedVariants().filter(variant => variant.mass).sort((a,b) => a.number - b.number) : [];
  });
  selectedNotMassVariants = computed(() => {
    return this.selectedVariants() ? this.selectedVariants().filter(variant => !variant.mass).sort((a,b) => a.number - b.number) : [];
  });
  selectedMassVariantCollectionsOfCollections = computed(() => {
    return [
      {variants :this.selectedMassVariants(), titleTranslationKey : 'diversity.in_focus'},
      {variants :this.selectedNotMassVariants(), titleTranslationKey : 'diversity.not_in_focus', helptextTranslationKey1 : 'diversity.not_in_focus_info1', helptextTranslationKey2 : 'diversity.not_in_focus_info2'},
    ];
  });

  cardWidth = input<number>(240);
  cardHeight = input<number>(400);
  showIcons = input<boolean>(false);
  show_legend = input<boolean>(true);
  show_legend_details = input<boolean>(false);
  toggleFilter = output<{filter_key:string, filter_value: boolean}>();
  toggleShowFilters = output<void>();
  toggleShowControls = output<void>();
  toggleShowLegend = output<void>();
  toggleShowLegendDetails = output<void>();
  showFilters = input<boolean>();
  autoPlayFilterLoop = input<boolean>();
  showControls = input<boolean>();
  hideNumberLabels = input<boolean>(false);
  hideTextLabels = input<boolean>(false);
  highlightMass = input<boolean>();
  labelType = input<string>('number');
  toggleLabelType = output<void>();

  doToggleLabelType(){
    this.toggleLabelType.emit();
  }
  doToggleShowLegend(){
    this.toggleShowLegend.emit();
  }
  doToggleShowLegendDetails(){
    this.toggleShowLegendDetails.emit();
  }
  doSelectCulture(culture: Culture){
    this.selectCulture.emit(culture);
  }
  doUpdateSelectedVariantFilters(variantFilters: CultureVariantFilter[]){
    this.selectedVariantFilters.set(variantFilters);
  }
  doToggleShowFilters(){
    this.toggleShowFilters.emit();
  }

}
