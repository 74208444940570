import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService, User } from '../../auth';
import { AvailableLanguage, Language, LanguageService, LanguageSkill } from '../../language';
import { ProfileUserService } from '../profile-user.service';

@Component({
  selector: 'profile-user-languages',
  templateUrl: './profile-user-languages.component.html',
  styleUrls: ['./profile-user-languages.component.scss']
})
export class ProfileUserLanguagesComponent implements OnInit, OnDestroy {

  activeLanguageObject = signal<AvailableLanguage | undefined>(undefined);
  subscriptions = signal<Subscription[]>([]);
  loadingObject = signal <{[key:string]:boolean}>({});
  myLanguages = this.profileUserService.myLanguages;
  allLanguages = signal<Language[]|undefined>(undefined);
  
  user = signal<User|undefined>(undefined);
  error = signal<string|{message:string,meta:any}|undefined>(undefined);


  constructor(
    private languageService: LanguageService,
    private authService: AuthService,
    public profileUserService: ProfileUserService,
  ) {}

  isAnythingLoading(){
    for (let something in this.loadingObject()){
      if (this.loadingObject()[something]){
        return true;
      }
    }
    return false;
  }

  getAllLanguages(freshFromServer: boolean){
    this.loadingObject()['allLanguages'] = true;
    const allLanguagesSubscription = this.languageService.getLanguages(freshFromServer)
      .subscribe(response=> {
        this.allLanguages.set(response);
        this.loadingObject()['allLanguages'] = false;
      });
  }

  getLanguages(freshFromServer: boolean){
    this.loadingObject()['languages'] = true;
    const getLanguagesSubscription = this.profileUserService.getMyLanguages(freshFromServer).subscribe(
      () => {
        this.loadingObject()['languages'] = false;
      },
      (error) => {
        this.loadingObject()['languages'] = false;
      }
    );

  }
  onRemoveLanguage(language : Language
  ){
    this.loadingObject()['languages'] = true;
    const deleteLanguageSubscription = this.profileUserService.deleteMyLanguage(language.id).subscribe(
      () => {
        this.loadingObject()['languages'] = false;
      },
      (error) => {
        this.loadingObject()['languages'] = false;
      }
    );
    this.subscriptions().push(deleteLanguageSubscription);
  }

  onSaveLanguage(languageSkill : LanguageSkill){

    const existingMyLanguage = this.myLanguages().find(myLanguage => myLanguage.id === languageSkill.language_id);
    // const saveLanguageObservable = existingMyLanguage ? this.profileUserService.updateMyLanguage : this.profileUserService.storeNewMyLanguage;

    this.loadingObject()['languages'] = true;

    let saveLanguageSubscription;

    if(existingMyLanguage){

      saveLanguageSubscription = this.profileUserService.updateMyLanguage(languageSkill).subscribe(
        () => {
          this.loadingObject()['languages'] = false;
        },
        (error) => {
          this.loadingObject()['languages'] = false;
        }
      );
    
    } else {

      saveLanguageSubscription = this.profileUserService.storeNewMyLanguage(languageSkill).subscribe(
        () => {
          this.loadingObject()['languages'] = false;
        },
        (error) => {
          this.loadingObject()['languages'] = false;
        }
      );

    }
      
    this.subscriptions().push(saveLanguageSubscription);
  }


  ngOnInit(): void {
    this.authService.user.subscribe(user => this.getLanguages(false));
    this.activeLanguageObject.set(this.languageService.activeLanguageObjectSynchronously);
    const activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( (newActiveLanguage) => {
        // TODO - find a better way to prevent this being called when the component initialises. It should be called only when the language changes
        if (this.activeLanguageObject() && newActiveLanguage?.languageKey !== this.activeLanguageObject().languageKey){
          this.activeLanguageObject.set(newActiveLanguage);
          this.getLanguages(true);
          this.getAllLanguages(true);
          
        }
    });
    this.getAllLanguages(false);
  }

  ngOnDestroy(): void {
    this.subscriptions().forEach(subscription => subscription.unsubscribe());
  }

}
