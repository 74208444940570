<div class="side-menu" *transloco="let t">
  <div class="img-holder">
    <div class="editable">
      <img class="profile-img" *ngIf="picture" [src]="picture"
      alt="Profile image">
      <button class="edit-image" *ngIf="pictureEditable" (click)="onPictureEdit()">
        <i class="fa-solid fa-pen"></i>
      </button>
    </div>
    <p class="name">{{ nameTranslationKey ? t(nameTranslationKey) : nameText}}</p>
  </div>

  <!-- <nav class="nav d-none d-lg-block"> -->
  <!-- <nav class="nav">
    <a
      *ngFor="let link of navigationLinks"  
      class="nav-link"
      [routerLink]="link.routerLinkValue"
      routerLinkActive="active"
      >
      {{link.labelLanguageKey ? t(link.labelLanguageKey) : link.labelText}}
    </a>
  </nav> -->

  <nav class="sub-nav" [ngClass]="{'open': isOpen}">
    <span class="menu-icon d-lg-none" (click)="isOpen = !isOpen" [ngClass]="{'open': isOpen}">
      <i [ngClass]="{'fa-solid fa-x': isOpen, 'fa-solid fa-ellipsis-vertical': !isOpen}"></i>
    </span>
    <a
      *ngFor="let link of navigationLinks"
      class="nav-link"
      [routerLink]="link.routerLinkValue"
      routerLinkActive="active"
    >
      <!-- <span class="icon">{{ link.icon }}</span> -->
      <!-- <span class="icon"><i class="fas fa-home"></i></span> -->
      <span class="text">
        {{ link.labelLanguageKey ? t(link.labelLanguageKey) : link.labelText }}
      </span>
    </a>
  </nav>

  <!-- Navigation on small devices -->
  <!-- <select class="form-select form-select-lg d-block d-lg-none" aria-label="nav-item-select" (change)="onMobileMenuSelect($event)" name="select-menu"> -->
  <!-- <select class="form-select form-select-lg d-none" aria-label="nav-item-select" (change)="onMobileMenuSelect($event)" name="select-menu"> -->
    <!-- <option selected>Active</option> -->
    <!-- <option [selected]="link.selected" [value]="link.routerLinkValue" *ngFor="let link of navigationLinks">{{link.labelLanguageKey ? t(link.labelLanguageKey) : link.labelText}}</option>
  </select> -->

</div>