import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageSelectorTypeOneComponent } from './language-selector-type-one/language-selector-type-one.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TranslocoModule } from '@jsverse/transloco';
import { UserLanguageEditorComponent } from './user-language-editor/user-language-editor.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FormControlsModule } from '../forms';



@NgModule({
  declarations: [
    LanguageSelectorTypeOneComponent,
    UserLanguageEditorComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormControlsModule,
    TypeaheadModule,
    BsDropdownModule,
    TranslocoModule,
  ],
  exports: [
    LanguageSelectorTypeOneComponent,
    UserLanguageEditorComponent
  ]
})
export class LanguageModule { }
