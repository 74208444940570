import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subscription, catchError, map, of, throwError } from 'rxjs';
import { MetaText } from './metaText.model';
import { ErrorService } from '../error';
import { LanguageService } from '../language';

@Injectable({
    providedIn: 'root'
})
export class MetaTextService {

    private _metaTextsByMetaTextableAndId: {metaTextable_type:string,metaTextable_id:number, metaTexts: MetaText[]}[] = [];
    activeLanguageSubscription: Subscription;

    constructor(
        private http: HttpClient,
        private errorService: ErrorService,
        private languageService: LanguageService,
    ) { 
        this.activeLanguageSubscription =
        this.languageService.activeLanguageObject.subscribe(() => {
          this.clearData();
      });

    }

    clearData() {
        this._metaTextsByMetaTextableAndId = [];
    }

    cacheMetaTexts(metaTextable_type:string,metaTextable_id:number,metaTexts: MetaText[]) {
        const index = this._metaTextsByMetaTextableAndId.findIndex(mt => mt.metaTextable_type === metaTextable_type && mt.metaTextable_id === metaTextable_id);
        if (index >= 0) {
            this._metaTextsByMetaTextableAndId[index].metaTexts = metaTexts;
        } else {
            this._metaTextsByMetaTextableAndId.push({metaTextable_type,metaTextable_id, metaTexts});
        }
    };

    getMetaTexts(freshFromServer: boolean, metaTextable_type:string,metaTextable_id:number,category:string): Observable<MetaText[]> {
        // category is the metatextable pivot category required by the backend for this route

        const cachedMetaTexts = this._metaTextsByMetaTextableAndId.find(mt => mt.metaTextable_type === metaTextable_type && mt.metaTextable_id === metaTextable_id);
        if (!freshFromServer && cachedMetaTexts) {
            return of(cachedMetaTexts.metaTexts);
        }

        return this.http.get<{ data: MetaText[] }>(`api/v1/metatexts/${metaTextable_type}/${metaTextable_id}?category=${category}`)
            .pipe(
                map(response => {
                    this.cacheMetaTexts(metaTextable_type,metaTextable_id,response.data);
                    return response.data;
                }),
                catchError((error) => this.handleError(error))
            );
    }

    private handleError(errorResponse: HttpErrorResponse) {
        let errorMessage = 'error.something_went_wrong';
        if (!errorResponse.error || !errorResponse.error.message) {
            return throwError(errorMessage);
        } else {
            const message = errorResponse.error.message;
            const standardErrorMessageTranslationKey = this.errorService.getCommonErrorMessageTranslationKey(message);
            if (standardErrorMessageTranslationKey) {
                errorMessage = standardErrorMessageTranslationKey;
            } else if (message.includes('Request latest data then try again.')) {
                errorMessage = 'error.refresh';
            }
            if (errorResponse.error.meta) {
                return throwError({ message: errorMessage, meta: errorResponse.error.meta });
            }
            return throwError(errorMessage);
        }
    }
}