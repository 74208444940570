<div
  class="content-holder"
  *transloco="let t"
>
  <div class="header align-items-center mb-4">
    <p class="title">

      <span *transloco="let t;scope: 'profile'">{{t('profile.my_language_skills')}}</span>
    </p>
    <div class="subtitle">
      <span *transloco="let t;scope: 'profile'">{{t('profile.my_language_skills_info')}}</span>
    </div>
  </div>

  <div class="row body">

    <multisite-user-language-editor
      [myLanguages]="myLanguages()"
      [allLanguages]="allLanguages()"
      (onDeleteLanguage)="onRemoveLanguage($event)"
      (onSaveLanguage)="onSaveLanguage($event)"
      [loading]="isAnythingLoading()"
    ></multisite-user-language-editor>


  </div>

  <div class="row body" *ngIf="isAnythingLoading()">

    <multisite-loader-globe-animation-detail
      width="80"
      height="80"
      class="my-3"
    ></multisite-loader-globe-animation-detail>


  </div>


</div>