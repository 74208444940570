 <!-- Content holder is card with flexible styling it can be reusable at the same parent -->
 <div class="content-holder" *transloco="let t">
  <div class="header align-items-center">
    <p class="title">
      <span>{{t('common.name')}}</span>
    </p>
    <div class="subtitle">You can update your name here</div>
  </div>
  <!-- 
      Use bootstrap grid system for content placment inside of body element
     -->
  <div class="row body">
    <!-- Place your contet here -->

    <multisite-coming-soon></multisite-coming-soon>


    <multisite-loader-globe-animation-detail *ngIf="loading" width="80" height="80" class="my-3"></multisite-loader-globe-animation-detail>



  </div>
  <!-- 
      Footer: justify-content-start, justify-content-center or justify-content-end
     -->
  <!-- <div class="footer justify-content-end">
    <button class="btn-sm btn-primary primary-dark" *ngIf="detailsForm == 'password'">Save changes</button>
  </div> -->
</div>